import { Link } from "wouter";
import MonthSelector from "../../components/monthSelector";
import { getYearMonthFormat } from "../../helper/date";
import useFetch from "../../helper/swr";
import usePersistentState from "../../helper/usePersistentState";
import InputSelect from "../../components/inputSelect";
import { useState } from "react";
import TableHeader from "../../components/tableHeader";
import { sortRows } from "../../helper/sorter";
import useSWR from "swr";
import fetcherClean from "../../helper/fetcherClean";

const TYPE_MAP = {
	line: "Linje",
	vehicle: "Fordon",
	stop: "Hållplats",
};

const DEVIATION_TYPE = {
	nc: "Ej slutförd",
	h19: "H19",
};

const FILTER_MAP = {
	ALL: "Visa alla",
	line: "Linje",
	vehicle: "Fordon",
	stop: "Hållplats",
};

const HEADERS = [
	{ name: "Avvikelse", width: "w-40", key: "deviationType" },
	{ name: "Typ", width: "w-40", key: "type" },
	{ name: "Orsak", width: "w-52", key: "reason" },
	{ name: "Antal", width: "w-40", key: "tripsCount" },
	{ name: "Procent", width: "w-40", key: "deviationProcentage" },
	{ name: "Källa", width: "w-40", key: "source" },
];

function TrendRow({ trend }: { trend: any }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<div>
			<div
				onClick={() => setExpanded(!expanded)}
				className="flex flex-row w-full items-center px-4 py-2 border-b dark:border-b-stone-800 hover:bg-stone-50 dark:hover:bg-stone-900 ease-out transition-all text-stone-800 dark:text-stone-300 text-sm"
			>
				<p className="w-40 whitespace-nowrap">{DEVIATION_TYPE[trend.deviationType]}</p>
				<p className="w-40 whitespace-nowrap">{TYPE_MAP[trend.type]}</p>
				<p className="w-52 whitespace-nowrap overflow-hidden text-ellipsis">
					{trend.reason}
				</p>
				<p className="w-40 whitespace-nowrap">{trend.tripsCount}st </p>
				<span className="w-40 flex">
					<p
						className={
							"px-1 py-0.5 rounded text-sm text-black font-medium flex text-center " +
							(trend.deviationProcentage >= 0.5
								? "bg-red-200 text-red-800 dark:bg-red-800 dark:text-red-200"
								: "bg-green-200 text-green-800 dark:bg-green-800 dark:text-green-200")
						}
					>
						{trend.deviationProcentage ? `${trend.deviationProcentage}%` : null}
					</p>
				</span>
				<p className="w-40 whitespace-nowrap">{trend.source}</p>
			</div>
			{expanded ? (
				<div className="flex flex-col w-full relative overflow-hidden">
					{trend.trips.map((trip, i: number) => (
						<Link
							to={`/resend/trips/${trip.operatingDay}/${trip.line}/${trip.trip}/${trend.deviationType}`}
							key={"trend-" + i}
							className="flex flex-row w-full overflow-x-scroll items-center px-4 py-2 border-b hover:bg-stone-50 ease-out transition-all text-gray-600 text-sm"
						>
							<div className="flex flex-row">
								<p className="mr-5">{trip.operatingDay}</p>
								<p>{trip.line}/</p>
								<p className="mr-5">{trip.trip}</p>
								<p>{trip.comment}</p>
							</div>
							<div className="flex flex-row">
								{trend.deviationType === "h19"
									? trip?.deviatedStops.map(({ stopName }: any) => (
											<>
												<p className="mr-10">
													{trip.deviatedStops.length}st
												</p>

												<p key={stopName} className="mr-5 text-nowrap">
													{stopName}
												</p>
											</>
									  ))
									: null}
							</div>
						</Link>
					))}
				</div>
			) : null}
		</div>
	);
}

const RenderTrends = ({
	trends,
	isLoading,
	isError,
	sortBy,
	categoryFilter,
}: {
	trends: any;
	isLoading: boolean;
	isError: boolean;
	sortBy: string;
	categoryFilter: string;
}) => {
	if (isLoading) {
		return <p className="text-xs mt-2 text-stone-500 font-medium">Laddar trender...</p>;
	}

	if (isError) {
		return <p className="text-xs mt-2 text-stone-500 font-medium">Ett fel uppstod</p>;
	}

	if (!trends || trends.length === 0) {
		return <p className="text-xs mt-2 text-stone-500 font-medium">Inga trender hittade</p>;
	}

	const { data: linesCount } = useSWR(
		"https://storage.vassdupp.com/linescount.json",
		fetcherClean,
		{
			errorRetryCount: 1,
			errorRetryInterval: 4000,
			dedupingInterval: 10000,
			shouldRetryOnError: false,
		}
	);

	const date = new Date();
	const daysInMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
	const lines = linesCount ? linesCount[daysInMonth] : {};

	const fillTrends = trends
		.map((trend: any) => {
			if (trend.type === "line" && linesCount) {
				return {
					...trend,
					deviationProcentage: parseFloat(
						((trend.tripsCount / lines[trend.reason]) * 100).toFixed(2)
					),
				};
			}

			return trend;
		})
		.filter(
			(trip: any) =>
				FILTER_MAP[categoryFilter] === "Visa alla" || trip.type === categoryFilter
		);

	return (
		<div className="flex flex-col">
			{sortRows(fillTrends, sortBy).map((trend: any, i: number) => (
				<TrendRow trend={trend} key={"dr-" + i} />
			))}
		</div>
	);
};

export default function Trends() {
	const [sortBy, setSortBy] = usePersistentState("vassdupp-trend-sort", "desc");

	const [startMonth, setStartMonth] = usePersistentState(
		"vassdupp-month-trend-start",
		getYearMonthFormat()
	);
	const [endMonth, setEndMonth] = usePersistentState(
		"vassdupp-month-trend-end",
		getYearMonthFormat()
	);

	const [tripFilter, setTripFilter] = usePersistentState("vassdupp-trend-filter-trip", "ALL");
	const [categoryFilter, setCategoryFilter] = usePersistentState(
		"vassdupp-trend-filter-category",
		"ALL"
	);

	const {
		data: trends,
		isLoading: isLoading,
		isError: isError,
	} = useFetch(`trends/${startMonth}/${endMonth}/${tripFilter}`);

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Trender</h1>

					<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-12 whitespace-nowrap">
						Avvikelser som sker på samma plats tur eller linje och har inträffat minst 5
						gånger på en månad.
					</p>
				</div>
				<div className="flex">
					<div className="mr-2">
						<InputSelect
							placeholder="Fitlera kategorier"
							items={[
								["ALL", "Allt"],
								["vehicle", "Fordon"],
								["line", "Linje"],
								["stop", "Hållplats"],
							]}
							value={categoryFilter}
							onChange={setCategoryFilter}
						/>
					</div>
					<InputSelect
						placeholder="Filtera turer"
						items={[
							["ALL", "Visa alla"],
							["NO-EXCEPTIONS", "Inga undantag"],
							["failed", "Ej slutförda"],
						]}
						value={tripFilter}
						onChange={setTripFilter}
					/>
					<MonthSelector
						value={startMonth}
						onChange={setStartMonth}
						placeholder="Från månad"
					/>
					<MonthSelector
						value={endMonth}
						onChange={setEndMonth}
						placeholder="Till månad"
					/>
				</div>
			</div>
			<div className="flex flex-col w-full mb-12">
				<TableHeader items={HEADERS} sortBy={sortBy} setSortBy={setSortBy} />
				<RenderTrends
					trends={trends}
					isError={isError}
					isLoading={isLoading}
					sortBy={sortBy}
					categoryFilter={categoryFilter}
				/>
			</div>
		</div>
	);
}
