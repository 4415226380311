import useSWR from "swr";
import fetcher from "./fetcher";

const useFetch = (url: string | null) => {
	const { data, error, mutate } = useSWR(url, fetcher, {
		errorRetryCount: 1,
		errorRetryInterval: 4000,
		dedupingInterval: 10000,
		shouldRetryOnError: false,
	});

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate,
		notFound: error?.response?.status === 404,
	};
};

export default useFetch;
