import React from "react";
import ReactDOM from "react-dom/client";

import { Route, Switch } from "wouter";
import { Toaster } from "react-hot-toast";

import "./index.css";

import Login from "./routes/login";
import BookingHelper from "./routes/bookinghelper";
import BusParking from "./routes/busParking";
import Start from "./routes/start";
import InfoDisplays from "./routes/infoDisplays";
import Users from "./routes/users";
import Logout from "./routes/logout";
import User from "./routes/user";
import InfoDisplay from "./routes/infoDisplay";
import Display from "./routes/display";
import Pincode from "./routes/pincode";
import InfoPlaylists from "./routes/infoPlaylists";
import InfoPlaylist from "./routes/infoPlaylist";
import CreateTimeDistanceGraph from "./routes/createTimeDistanceGraph";
import ReplacementTrips from "./routes/replacementTrips";
import NotFinished from "./routes/resend/notFinished";
import H19Trips from "./routes/resend/h19Trips";
import PlannedDeviatedTrips from "./routes/resend/plannedDeviatedTrips";
import FinedTrips from "./routes/resend/finedTrips";
import Resent from "./routes/resend/resent";
import Reports from "./routes/resend/reports";
import Trends from "./routes/resend/trends";
import Status from "./routes/resend/status";
import ViewH19Trip from "./routes/resend/viewh19Trip";
import ViewNotCompeletedTrip from "./routes/resend/viewNotCompletedTrip";
import ValidateTrips from "./routes/resend/validateTrips";
import TrafficChanges from "./routes/resend/trafficChanges";
import Schedule from "./routes/schedule";
import BusParkingAdmin from "./routes/busParkingAdmin";

import Root from "./components/root";
import Overview from "./routes/trackem/overview";

const App = () => (
	<Switch>
		<Route path="/" component={Login} />
		<Route path="/display/:displayId" component={Display} />
		<Route path="/logout" component={Logout} />
		<Route path="/" nest>
			<Root>
				<Switch>
					<Route path="/start" component={Start} />
					<Route path="/bookinghelper" component={BookingHelper} />
					<Route path="/createtimedistancegraph" component={CreateTimeDistanceGraph} />

					<Route path="/infoplaylists" component={InfoPlaylists} />
					<Route path="/infoplaylists/:playlistId" component={InfoPlaylist} />
					<Route path="/infodisplays" component={InfoDisplays} />
					<Route path="/infodisplays/:displayId" component={InfoDisplay} />

					<Route path="/replacementtrips" component={ReplacementTrips} />
					<Route path="/replacementtrips/:id" component={ReplacementTrips} />

					<Route path="/users" component={Users} />
					<Route path="/users/:username" component={User} />

					<Route path="/pincode" component={Pincode} />

					<Route
						path="/resend/trips/:operatingDay/:line/:trip/h19"
						component={ViewH19Trip}
					/>
					<Route
						path="/resend/trips/:operatingDay/:line/:trip/nc"
						component={ViewNotCompeletedTrip}
					/>
					<Route path="/resend/notfinishedtrips" component={NotFinished} />
					<Route path="/resend/h19trips" component={H19Trips} />
					<Route path="/resend/planneddeviated" component={PlannedDeviatedTrips} />
					<Route path="/resend/finedtrips" component={FinedTrips} />
					<Route path="/resend/resent" component={Resent} />
					<Route path="/resend/trafficchanges" component={TrafficChanges} />
					<Route path="/resend/reports" component={Reports} />
					<Route path="/resend/trends" component={Trends} />
					<Route path="/resend/status" component={Status} />
					<Route path="/resend/validatetrip" component={ValidateTrips} />

					<Route path="/trackem/overview" component={Overview} />

					<Route path="/parking/admin" component={BusParkingAdmin} />
					<Route path="/parking" component={BusParking} />
					<Route path="/schedule" component={Schedule} />
				</Switch>
			</Root>
		</Route>
	</Switch>
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<App />
		<Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
	</React.StrictMode>
);
