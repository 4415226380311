import { useState } from "react";
import postman from "../../helper/postman";

import Button from "../../components/button";
import MonthSelector from "../../components/monthSelector";
import {
	caluclateDateString,
	getDateHoursAndMinutes,
	getYearMonthFormat,
	prettyFullDate,
	smallToBigDate,
	smallToDate,
} from "../../helper/date";
import { utils } from "xlsx";
import { createXLSXFile, createXLSXSheet, getWSJSON } from "../../helper/xlsx";
import { STATUS_TITLE_MAP } from "../../config";
import { zeroed } from "../../helper/misc";
import TRIP_TRAFFIC_AREA_OWBER from "../../data/lines";
import usePersistentState from "../../helper/usePersistentState";
import InputFile from "../../components/inputFile";
import Modal from "../../components/modal";
import toast from "react-hot-toast";
import useFetch from "../../helper/swr";

const createTripKey = (row: any) => {
	return `${prettyFullDate(new Date(row.Trafikdatum).getTime()).replaceAll(
		"-",
		""
	)}-${row.Linje.toString().substring(0, 3)}-${row.Tur}`;
};

const calculateH19Status = (row: any) => {
	if (row.deviationType === "h19") {
		return row.Orsak.toString().toLowerCase() === "falsk" ? "false-h19" : "real-h19";
	}

	if (row.deviationType === "del" || row.deviationType === "hel") {
		return "cancelled";
	}

	return "waiting-decision";
};

const caluclateH19Progress = (item: any, trip) => {
	if (!item || (!item.plannedDepartureTime && !item.plannedArrivalTime)) {
		console.log("No actual time", item, trip);
	}

	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / (1000 * 60);

	return timeDifference;
};

const genTimeDiffBetweenStops = (startStop: any, endStop: any) => {
	const actualStart = startStop?.actualDepartureTime ?? startStop.actualArrivalTime;
	const acutalEnd = endStop?.actualDepartureTime ?? endStop.actualArrivalTime;
	const plannedDateStartDate = new Date(
		actualStart ?? startStop?.plannedDepartureTime ?? startStop.plannedArrivalTime
	);
	const plannedDateEndDate = new Date(
		acutalEnd ?? endStop?.plannedDepartureTime ?? endStop.plannedArrivalTime
	);

	const timeDifference =
		(plannedDateEndDate.getTime() - plannedDateStartDate.getTime()) / (1000 * 60);

	return parseInt(Math.abs(timeDifference).toFixed(2));
};

function getRandomNumber(div: string, row): number {
	if (!div) {
		return 0;
	}

	const DIV_MAP: any = {
		E31: [400, 443],
		E35: [5010, 5060],
		E38: [5543, 5559],
	};

	const [min, max] = DIV_MAP[div.toUpperCase()];

	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getTripCancelledInfo = (calls: any) => {
	const tripsWithDeviations = calls.filter(
		(call) =>
			(call.plannedArrivalTime !== null && call.actualArrivalTime === null) ||
			(call.plannedDepartureTime !== null && call.actualDepartureTime === null)
	);

	if (tripsWithDeviations.length === 0) {
		return null;
	}

	if (tripsWithDeviations.length === 1) {
		if (tripsWithDeviations[0].sequenceInJourney === calls.length) {
			tripsWithDeviations.unshift(calls[calls.length - 2]);
		} else {
			tripsWithDeviations.push(calls[tripsWithDeviations[0].sequenceInJourney]);
		}
	}

	let firstStopAffected = tripsWithDeviations[0];
	let lastStopAffected = tripsWithDeviations[tripsWithDeviations.length - 1];

	let distanceAffected = 0;
	let timeAffected = 0;

	let totalDistance = 0;

	for (let i = 0; i < calls.length - 1; i++) {
		const start = calls[i];
		totalDistance += start.distanceToNextStop;
	}

	for (let i = 0; i < tripsWithDeviations.length - 1; i++) {
		const start = tripsWithDeviations[i];
		const end = tripsWithDeviations[i + 1];
		const startTime = new Date(
			start.plannedArrivalTime ?? start.plannedDepartureTime
		).getTime();
		const endTime = new Date(end.plannedArrivalTime ?? end.plannedDepartureTime).getTime();

		timeAffected += endTime - startTime;
		distanceAffected += start.distanceToNextStop;
	}

	return {
		firstStopAffected: firstStopAffected.stopName,
		lastStopAffected: lastStopAffected.stopName,
		distanceAffected: (distanceAffected / 1000).toFixed(2),
		timeAffected: (timeAffected / 60000).toFixed(2),
		startStop: calls[0].stopName,
		endStop: calls[calls.length - 1].stopName,
		totalDistance: (totalDistance / 1000).toFixed(2),
		tripsWithDeviations,
	};
};

export default function Reports() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState<any>("ALL");
	const [addStatusPop, setAddStatusPop] = useState(false);

	const { data: h19Trips } = useFetch(`h19s?contract=${contract}&month=${month}&page=${0}`);

	const { data: deivatedTrips } = useFetch(
		`notcomplete?contract=${contract}&month=${month}&page=${0}&filter=${"A"}`
	);

	const calculateH19Stats = (trip: any) => {
		const stopsWithH19 = [];

		for (let i = 0; i < trip.calls.length; i++) {
			const call = trip.calls[i];
			const timeDiff = caluclateH19Progress(call, trip);

			if (timeDiff >= 19) {
				stopsWithH19.push({ ...call, index: i, timeDiff });
			}
		}

		// If there is only one stop with h19, add the stop after it.
		if (stopsWithH19.length === 1 && stopsWithH19[0].index !== trip.calls.length - 1) {
			stopsWithH19.push({ ...trip.calls[stopsWithH19[0].index + 1], timeDiff: 0 });
		} else if (stopsWithH19.length === 1 && stopsWithH19[0].index !== 0) {
			stopsWithH19.unshift({ ...trip.calls[stopsWithH19[0].index - 1], timeDiff: 0 });
		}

		if (!trip.plannedDistance) {
			console.log("No planned distance", trip);
		}

		if (stopsWithH19.length === 0) {
			return {
				startStop: "-",
				endStop: "-",
				minutesLate: 0,
				timeDiffBetweenStops: 0,
				diff: 0,
			};
		}

		const startStop = stopsWithH19[0];
		const endStop = stopsWithH19[stopsWithH19.length - 1];
		const minutesLate = stopsWithH19.sort((a, b) => b.timeDiff - a.timeDiff)[0].timeDiff;

		const absTimeDiffBetweenStops = genTimeDiffBetweenStops(
			trip.calls[0],
			trip.calls[trip.calls.length - 1]
		);
		const timeDiffBetweenStops = genTimeDiffBetweenStops(startStop, endStop);
		const diff = parseFloat((timeDiffBetweenStops / absTimeDiffBetweenStops).toFixed(2));
		const cleanDiff = diff > 1 || diff < 0 || stopsWithH19.length === 0 ? 0 : diff;

		console.log(startStop, endStop, minutesLate, timeDiffBetweenStops, diff, cleanDiff);

		return {
			startStop,
			endStop,
			minutesLate,
			timeDiffBetweenStops,
			diff: cleanDiff,
		};
	};

	const getH19StatusFULL = (status: any, resent: boolean, oneHourDiff: boolean) => {
		if (oneHourDiff && resent && status === "false-h19") {
			return "Eftersänd med felaktig tidzon";
		}

		return STATUS_TITLE_MAP[status];
	};

	const genSpecTrips = async (contact: string) => {
		console.log("Generate production");

		const response = await postman.get(
			`planneddeviatedtrips?contract=${contact}&date=${month}`
		);

		if (!response.data) {
			console.log("No data found");
			return;
		}

		// HEADERS: Datum	Linje	Tur	Planerad KM	Planerad tid	Från HPL	Till HPL	SlutHPL	Påverkade km	Påverkade minuter	% inställd
		const data = response.data;

		const HEADERS = [
			"Trafikdatum",
			"Linje",
			"Tur",
			"Planerad KM",
			"Planerad tid",
			"Från HPL",
			"Till HPL",
			"SlutHPL",
			"Påverkade km",
			"Påverkade minuter",
			"% inställd",
		];

		const specialTrips = data.map((row) => [
			smallToDate(row.operatingDay),
			row.line,
			row.trip,
			Math.round(row.plannedDistance / 1000),
			row.plannedTime,
			row.startStop,
			row.endStop,
			row.deviationStartStop,
			((row.plannedDistance - row.actualDistance) / 1000).toFixed(2),
			(row.plannedTime - row.actualTime).toFixed(2),
			Math.round((row.actualDistance / row.plannedDistance) * 100),
		]);

		const wb = utils.book_new();

		// Create worksheet.
		createXLSXSheet(wb, "Inställda", HEADERS, specialTrips);

		// Create file.
		createXLSXFile(wb, `special-trips-${contact}-${month}.xlsx`);
	};

	const caluclateIfFailedResend = async (h19Trips: any[]) => {
		const keyedResult: any = {};

		return keyedResult;

		// Fetch resent trips and key them.
		const resentTrips: any = (await postman.get("/resenttrips"))?.data;

		if (!resentTrips) {
			console.log("No data found");
			alert("No data found");
			return;
		}

		const resentTripsKeyed = {};

		for (let i = 0; i < resentTrips.length; i++) {
			const resent = resentTrips[i];
			const trips = JSON.parse(resent.trips);

			for (let j = 0; j < trips.length; j++) {
				const tripRow = trips[j];
				const [_, operatingDay, line, trip] = tripRow.split("-");
				const key = `${operatingDay}-${line.substring(0, 3)}-${trip}`;

				resentTripsKeyed[key] = true;
			}
		}

		for (let i = 0; i < h19Trips.length; i++) {
			const trip = h19Trips[i];
			const key = `${trip.operatingDay}-${trip.line}-${trip.trip}`;
			const deviatedStopsKeyed = {};
			const deviatedStopsByTime = {};

			if (trip.calls.length === 0) {
				console.log("No calls", trip);
				continue;
			}

			for (let i = 0; i < trip.deviatedStops.length; i++) {
				const deviatedStop = trip.deviatedStops[i];

				deviatedStopsKeyed[deviatedStop.name] = deviatedStop;

				const actualTimeAdjusted =
					"T" +
					caluclateDateString(
						trip.calls[0].operatingDay,
						deviatedStop.plannedTime
					).toLocaleTimeString();
				const [TFHurs, TFMinutes] = getDateHoursAndMinutes(actualTimeAdjusted);
				deviatedStopsByTime[TFHurs + ":" + TFMinutes] = deviatedStop;
			}

			let has1hourDelayAtleastOneStop = false;

			for (let j = 0; j < trip.calls.length; j++) {
				const stop = trip.calls[j];
				const [P_TTHurs, P_TTMinutes] = getDateHoursAndMinutes(
					stop.plannedArrivalTime ?? stop.plannedDepartureTime
				);
				const deviatedStop =
					deviatedStopsKeyed[stop.stopName] ??
					deviatedStopsByTime[P_TTHurs + ":" + P_TTMinutes];

				if (
					!deviatedStop ||
					(stop.actualArrivalTime === null && stop.actualDepartureTime === null)
				) {
					continue;
				}

				const actualTimeAdjusted =
					"T" +
					caluclateDateString(
						stop.operatingDay,
						deviatedStop.actualTime
					).toLocaleTimeString();
				const [TFHurs, TFMinutes] = getDateHoursAndMinutes(actualTimeAdjusted);
				const [TTHurs, TTMinutes] = getDateHoursAndMinutes(
					stop.actualArrivalTime ?? stop.actualDepartureTime
				);

				if (TFHurs === TTHurs + 1 && TFMinutes === TTMinutes) {
					has1hourDelayAtleastOneStop = true;
					break;
				}
			}

			keyedResult[key] = {};
			keyedResult[key].resent = !!resentTripsKeyed[key];
			keyedResult[key].oneHourDiffOne = !!has1hourDelayAtleastOneStop;
		}

		return keyedResult;
	};

	const generateH19 = async () => {
		const trips = h19Trips;

		if (!trips) {
			console.log("No data found");
			alert("No data found");
			return;
		}

		const trips_to_caluclate = trips;

		const FIXED_TRIPS = [];

		const tripDiffStatus = await caluclateIfFailedResend(trips_to_caluclate);
		for (let i = 0; i < trips_to_caluclate.length; i++) {
			const trip = trips_to_caluclate[i];
			const { startStop, endStop, minutesLate, timeDiffBetweenStops, diff } =
				calculateH19Stats(trip);

			FIXED_TRIPS.push({
				operatingDay: smallToDate(trip.operatingDay),
				line: trip.line,
				trip: trip.trip,
				vehicle: trip.vehicle,
				contract: trip.contract,
				rtId: trip.disruptionNumber,
				start: startStop.stopName,
				end: endStop.stopName,
				late: minutesLate.toFixed(2),
				diff: timeDiffBetweenStops > 1 ? timeDiffBetweenStops.toFixed() : 2,
				distance: ((trip.plannedDistance / 1000) * (diff === 0 ? 0.2 : diff)).toFixed(2),
				reason: "",
				description: trip.description ?? "",
				tripResent:
					tripDiffStatus[`${trip.operatingDay}-${trip.line}-${trip.trip}`]?.resent,
				oneHourDiffOne:
					tripDiffStatus[`${trip.operatingDay}-${trip.line}-${trip.trip}`]
						?.oneHourDiffOne,
				status: trip.status,
				comment: trip.comment,
			});
		}

		const wb = utils.book_new();

		const HEADERS = [
			"Avtal",
			"Trafikdatum",
			"Starttid",
			"Tur",
			"Linje",
			"FordonsID",
			"Från HPL",
			"SlutHPL",
			"Minuter sen",
			"Påverkade km",
			"Påverkad tid",
			"Störningsnummer",
			"Orsak",
			"Intern kommentar",
			"Gulmarkerad kommentar",
			"Status",
		];

		const ROWS = FIXED_TRIPS.map((row) => [
			TRIP_TRAFFIC_AREA_OWBER[row.line],
			row.operatingDay,
			`${row.trip.substring(1, 3)}:${row.trip.substring(3, 5)}`,
			row.trip,
			row.line,
			row.vehicles ?? zeroed(getRandomNumber(TRIP_TRAFFIC_AREA_OWBER[row.line], row)),
			row.start,
			row.end,
			row.late,
			row.distance,
			row.diff,
			row.rtId,
			row.reason,
			row.internalComment,
			"",
			getH19StatusFULL(row.status, row.tripResent, row.oneHourDiffOne) +
				(row.comment ? `, ${row.comment}` : ""),
		]);

		createXLSXSheet(wb, "Inställda", HEADERS, ROWS);

		createXLSXFile(wb, `production-h19-${month}.xlsx`);
	};

	const handleTripStatusUpload = (file: any) => {
		if (!file) {
			return;
		}

		try {
			setAddStatusPop(false);

			const reader = new FileReader();
			reader.onload = async (event: any) => {
				// Parse data
				const bstr = event.target.result;
				const EXCEL_ROWS_H19_TRIPS = getWSJSON(bstr, 0, "H19");
				const EXCEL_ROWS_DEL_TRIPS = getWSJSON(bstr, 0, "Helinställda");
				const EXCEL_ROWS_HEL_TRIPS = getWSJSON(bstr, 0, "Delinställda");

				const PRODUCTION_TRIPS_KEYED = {};

				for (let i = 0; i < EXCEL_ROWS_H19_TRIPS.length; i++) {
					const row = EXCEL_ROWS_H19_TRIPS[i];

					PRODUCTION_TRIPS_KEYED[createTripKey(row)] = {
						...row,

						deviationType: "h19",
					};
				}

				for (let i = 0; i < EXCEL_ROWS_DEL_TRIPS.length; i++) {
					const row = EXCEL_ROWS_DEL_TRIPS[i];

					PRODUCTION_TRIPS_KEYED[createTripKey(row)] = {
						...row,
						deviationType: "del",
					};
				}

				for (let i = 0; i < EXCEL_ROWS_HEL_TRIPS.length; i++) {
					const row = EXCEL_ROWS_HEL_TRIPS[i];

					PRODUCTION_TRIPS_KEYED[createTripKey(row)] = {
						...row,
						deviationType: "hel",
					};
				}

				const H19_TRIPS_TO_CHANGE = [];
				const DEVIATED_TRIPS_TO_CHANGE = [];

				for (let i = 0; i < h19Trips.length; i++) {
					const trip = h19Trips[i];

					// Check if trip is waiting for.
					if (trip.status !== "waiting-decision" && trip.status !== "waiting-data") {
						continue;
					}

					// Check if trip is in the excel file.
					const row =
						PRODUCTION_TRIPS_KEYED[`${trip.operatingDay}-${trip.line}-${trip.trip}`];

					console.log(row);

					if (!row) {
						continue;
					}

					H19_TRIPS_TO_CHANGE.push({
						trip: trip.trip,
						operatingDay: trip.operatingDay,
						line: trip.line,
						calls: trip.calls,
						vehicle: trip.vehicle,
						comment: row.Internkommentar,
						status: calculateH19Status(row),
					});
				}

				for (let i = 0; i < deivatedTrips.length; i++) {
					const trip = deivatedTrips[i];

					// Check if trip is waiting for.
					if (
						trip.status !== "waiting-decision" &&
						trip.status !== "waiting-data" &&
						trip.status !== "resend-failed" &&
						trip.status !== "looks-wierd" &&
						trip.status !== "no-vehicle-data"
					) {
						continue;
					}

					// Check if trip is in the excel file.
					const row =
						PRODUCTION_TRIPS_KEYED[`${trip.operatingDay}-${trip.line}-${trip.trip}`];

					if (!row) {
						continue;
					}

					DEVIATED_TRIPS_TO_CHANGE.push({
						trip: trip.trip,
						operatingDay: trip.operatingDay,
						line: trip.line,
						comment: row.Internkommentar,
						status: calculateH19Status(row),
					});
				}

				// Check if there is any trips to change.
				if (H19_TRIPS_TO_CHANGE.length !== 0) {
					toast.promise(postman.post("/h19s/tripswithoutdatafull", H19_TRIPS_TO_CHANGE), {
						loading: "Lägger till h19-turer...",
						success: `H19-turer har ändrats: ${H19_TRIPS_TO_CHANGE.length}st`,
						error: "Ett fel uppstod vid ändring av h19-turer",
					});
				} else {
					toast.error("Inga turer att ändra");
				}

				// Check if there is any trips to change.
				if (DEVIATED_TRIPS_TO_CHANGE.length !== 0) {
					toast.promise(postman.post("/tripswithoutdata", DEVIATED_TRIPS_TO_CHANGE), {
						loading: "Lägger till ej slutförda turer...",
						success: `Ej slutförda turer har ändrats: ${DEVIATED_TRIPS_TO_CHANGE.length}st`,
						error: "Ett fel uppstod vid ändring av ej slutförda turer",
					});
				} else {
					toast.error("Inga turer att ändra");
				}
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			console.log(error);
			toast.error("Ett fel uppstod");
		}
	};

	const generateCancelled = async () => {
		const wb = utils.book_new();

		const trips: any = await toast.promise(
			postman
				.get(`notcomplete?contract=${contract}&month=${month}&filter=${"LCR"}&full=true`)
				.then((res) => res.data),
			{
				loading: "Hämtar inställda turer...",
				success: "Turer hämtade",
				error: "Ett fel uppstod vid hämtning av inställda turer",
			}
		);

		let cancelledTrips = trips.map((trip) => {
			const tripCancelled = getTripCancelledInfo(trip.calls);

			if (!tripCancelled) {
				console.log("No trip cancelled", trip);
				return null;
			}

			return [
				trip.contract,
				smallToDate(trip.operatingDay),
				trip.startTime,
				trip.trip,
				trip.line,
				trip.vehicle,
				tripCancelled.firstStopAffected,
				tripCancelled.lastStopAffected,
				tripCancelled.endStop,
				tripCancelled.totalDistance,
				tripCancelled.distanceAffected,
				tripCancelled.timeAffected,
				STATUS_TITLE_MAP[trip.status] ?? "",
			];
		});

		const HEADERS = [
			"Avtal",
			"Trafikdatum",
			"Starttid",
			"Tur",
			"Linje",
			"FordonsID",
			"Från HPL",
			"Till HPL",
			"SlutHPL",
			"Tur KM",
			"Påverkade km",
			"Påverkade minuter",
			"TLÅ",
			"Störningsnummer",
			"Intern kommentar",
			"Orsak",
			"% utförd tur",
			"Typ av tur",
		];

		createXLSXSheet(wb, "Inställda", HEADERS, cancelledTrips);

		createXLSXFile(wb, `Inställda-i-efterhand-${month}.xlsx`);
	};

	return (
		<>
			<Modal show={addStatusPop} closeModal={setAddStatusPop}>
				<div className="px-6 py-4 rounded">
					<h1 className="font-semibold">Ändra status på flera turer</h1>
					<p className="text-sm mb-5">
						Lägg till excel fil, viktigt att ha samma namn som i produktionsunderlag
					</p>
					<InputFile name="trips" accept=".xlsx" onHandleFile={handleTripStatusUpload} />
				</div>
			</Modal>
			<div className="flex flex-1 px-6 flex-col">
				<div className="flex w-full justify-between items-center mb-6">
					<div>
						<h1 className="mt-12 font-semibold text-2xl">Rapporter</h1>
						<p className="font-medium text-stone text-sm w-40 text-stone-500 whitespace-nowrap">
							Här kan du skapa rapporter för en specifik månad
						</p>
					</div>
					<div className="flex">
						<MonthSelector value={month} onChange={setMonth} />
					</div>
				</div>

				<h1 className="mt-12 font-semibold text-lg">Importera</h1>
				<div className="flex flex-col w-full items-start">
					<Button
						color="green"
						onClick={() => setAddStatusPop(true)}
						text="Ändra status på flera turer"
					/>
					<p className="text-xs ml-0.5">(Ladda upp produktionsuppföljning)</p>
				</div>
				<h1 className="mt-12 font-semibold text-lg">Exportera</h1>
				<div className="flex flex-col w-full items-start">
					<p className="mb-2 mt-2 text-stone-600 font-semibold text-sm">Generera</p>
					<div className="space-y-2">
						<Button
							text="Generera inställda i efterhand och omlagda"
							color="blue"
							onClick={() => generateCancelled()}
						/>
						<Button
							text="Generera dataunderlag"
							color="blue"
							onClick={() => console.log("Generate data")}
							disabled
						/>
						<Button
							text="Generera h19-underlag"
							color="blue"
							onClick={() => generateH19()}
						/>
						<Button
							text="Generera specialturer E31"
							color="blue"
							onClick={() => genSpecTrips("E31")}
						/>
						<Button
							text="Generera specialturer E38"
							color="blue"
							onClick={() => genSpecTrips("E38")}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
