const allRoutes = [
	[
		"Trafikledning",
		[
			["parking", "Parkering"],
			["pincode", "Pinkod"],
			["replacementtrips", "Ersättningstrafik"],
			/* ["edu", "Utbildning", "Alpha"], */
			/* 			["tva", "TVA", "Alpha"],
			["tlsimulator", "TL Simulator", "Alpha"], */
			/* 	["schedule", "Schedule", "Beta"], */
		],
	],
	["TrackEM", [["trackem/overview", "Översikt"]]],
	[
		"Resend",
		[
			["resend/notfinishedtrips", "Ej slutförda"],
			["resend/h19trips", "H19"],
			/* 			["resend/planneddeviated", "Planerade avvikelser"], */
			/* ["resend/finedtrips", "Vitesbelagt"],
			["resend/resent", "Eftersändningar"],
			["resend/trafficchanges", "Trafikändringar"], */
			["resend/reports", "Rapporter"],
			["resend/trends", "Trender"],
			["resend/status", "Systemstatus"],
			/* ["resend/validatetrip", "Validera turer"], */
		],
	],
	/* [
		"Trafikanalys",
		[ */
	/* 			["datafollowup", "Eftersändning"], */
	/* 			["datamergevehicle", "DMV"],
			["data574", "DMW 574"], */
	/* ["tripanalyzer", "Turanalys", "Alpha"], */
	/* 			["specialtrips", "Specialturer", "Alpha"], */
	/* 			["genctsreport", "CTS-avvikelser"], */
	/* ["bookinghelper", "Anropstrafikrapport"], */
	/* 			["apchelper", "APC-rapport"], */
	/* ["createtimedistancegraph", "Roslagsbanan ST-graf"], */
	/* 			["createProductionReport", "Produktionsuppföljning"],
			["createMonthlyProductionReport", "Produktionsunderlag"], */
	/* ["trafficareareport", "Körtidsrapport Trafikområde"],
			["drivingreport", "Körtidsrapport Förare"],
/* 			["triptinder", "TripTinder"],
			["man-datafollowup", "Manuell datauppföljning"],
			["trip-merge", "Trip merger"],
			["tripprogresschecker", "Trip progress checker"],
			["filemerger", "Merge files"], */
	/* 	],
	], */
	[
		"Info",
		[
			["infoPlaylists", "Spellistor" /*  "Beta" */],
			["infodisplays", "Skärmar" /*  "Beta" */],
			/* ["traffictech", "Trafikteknik", "Alpha"], */
		],
	],
	["Admin", [["users", "Användare"]]],
];

const routesMap: any = {
	apc: ["Trafikanalys", [["apchelper", "APC helper"]]],
	tdg: [
		"Trafikanalys",
		[
			["createtimedistancegraph", "TDG"],
			["replacementtrips", "Ersättningstrafik"],
		],
	],
	bp: [
		"Fordon",
		[
			["parking", "Parkering"],
			["pincode", "Pinkod"],
			["replacementtrips", "Ersättningstrafik"],
		],
	],
};

export const getNavigation = (user: {
	username: string;
	role: string;
	token: string;
	expires: string;
	firstname: string;
	lastname: string;
	permissions: string;
}) => {
	let res: (string | string[][])[][] = [];

	if (!user || user?.permissions === undefined) {
		return [];
	}

	if (user.role === "admin" || user.role === "god") {
		return [...allRoutes];
	} else {
		res = [];
	}

	let permissions = user?.permissions.split(" ");

	for (let i = 0; permissions.length > i; i++) {
		if (routesMap[permissions[i]]) {
			res = [...res, routesMap[permissions[i]]];
		}
	}

	return res;
};
