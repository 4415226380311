import axios from "axios";
import { VASSDUPP_URL } from "../config";

const postman = axios.create({
	baseURL: VASSDUPP_URL,
});

const fetcherClean = (url: string) => postman.get(url).then((res) => res.data);

export default fetcherClean;
