import { useEffect, useState } from "react";
import Modal from "../components/modal";
import Input from "../components/input";
import InputSelect from "../components/inputSelect";
import { prettyDate, prettyTime } from "../helper/date";
import toast from "react-hot-toast";
import useFetch from "../helper/swr";
import postman from "../helper/postman";
import usePersistentState from "../helper/usePersistentState";
import { sortRows } from "../helper/sorter";
import InputDateTime from "../components/inputDateTime";
import busTypes from "../data/vehicles/bustypes";
import { Circle, MapContainer, TileLayer } from "react-leaflet";
import { useLocation, useParams } from "wouter";
import InputSorter from "../components/inputSorter";

const headers = [
	{ key: "scheduledDepartureTime", name: "Avgångstid", width: "w-32" },
	{ key: "type", name: "Typ", width: "w-24" },
	{ key: "busesOrdered", name: "Bussar", width: "w-20" },
	{ key: "stations", name: "Stationer", width: "w-60" },
	{ key: "comment", name: "Kommentar", width: "flex-1" },
	{ key: "status", name: "Status", width: "w-40" },
];

const STATIONS = [
	{ name: "Danderyds sjukhus", lines: ["L27", "L28", "L29"], position: [59.396, 18.036] },
	{ name: "Mörby station", lines: ["L27", "L28", "L29"], position: [59.403, 18.045] },
	{ name: "Djursholms Ösby", lines: ["L27", "L28", "L29"], position: [59.398, 18.065] },
	{ name: "Roslags Näsby", lines: ["L27", "L28"], position: [59.435, 18.07] },
	{ name: "Tibble", lines: ["L27"], position: [59.443, 18.072] },
	{ name: "Ensta", lines: ["L27"], position: [59.45, 18.073] },
	{ name: "Visinge", lines: ["L27"], position: [59.457, 18.075] },
	{ name: "Täby kyrkby", lines: ["L27"], position: [59.468, 18.077] },
	{ name: "Kragstalund", lines: ["L27"], position: [59.482, 18.078] },
	{ name: "Bällsta", lines: ["L27"], position: [59.493, 18.079] },
	{ name: "Vallentuna", lines: ["L27"], position: [59.507, 18.08] },
	{ name: "Ormsta", lines: ["L27"], position: [59.52, 18.081] },
	{ name: "Molnby", lines: ["L27"], position: [59.533, 18.082] },
	{ name: "Lindholmen", lines: ["L27"], position: [59.546, 18.083] },
	{ name: "Frösunda", lines: ["L27"], position: [59.559, 18.084] },
	{ name: "Ekskogen", lines: ["L27"], position: [59.572, 18.085] },
	{ name: "Kårsta", lines: ["L27"], position: [59.585, 18.086] },
	{ name: "Bråvallavägen", lines: ["L28"], position: [59.41, 18.07] },
	{ name: "Djursholms Ekeby", lines: ["L28"], position: [59.415, 18.075] },
	{ name: "Enebyberg", lines: ["L28"], position: [59.42, 18.08] },
	{ name: "Täby centrum", lines: ["L28"], position: [59.425, 18.085] },
	{ name: "Galoppfältet", lines: ["L28"], position: [59.43, 18.09] },
	{ name: "Viggbyholm", lines: ["L28"], position: [59.435, 18.095] },
	{ name: "Hägernäs", lines: ["L28"], position: [59.44, 18.1] },
	{ name: "Arninge", lines: ["L28"], position: [59.445, 18.105] },
	{ name: "Rydbo", lines: ["L28"], position: [59.45, 18.11] },
	{ name: "Täljö", lines: ["L28"], position: [59.455, 18.115] },
	{ name: "Åkers Runö", lines: ["L28"], position: [59.46, 18.12] },
	{ name: "Åkersberga", lines: ["L28"], position: [59.465, 18.125] },
	{ name: "Tunagård", lines: ["L28"], position: [59.47, 18.13] },
	{ name: "Österskär", lines: ["L28"], position: [59.475, 18.135] },
	{ name: "Vendevägen", lines: ["L29"], position: [59.38, 18.04] },
	{ name: "Östberga", lines: ["L29"], position: [59.385, 18.045] },
	{ name: "Altorp", lines: ["L29"], position: [59.39, 18.05] },
	{ name: "Lahäll", lines: ["L29"], position: [59.395, 18.055] },
	{ name: "Näsby alle", lines: ["L29"], position: [59.4, 18.06] },
	{ name: "Näsbypark", lines: ["L29"], position: [59.405, 18.065] },
];

const STATIONS_OBJECT = {};

for (let i = 0; i < STATIONS.length; i++) {
	const station = STATIONS[i];

	STATIONS_OBJECT[station.name] = station;
}

const STATIONS_ONLY_SMALL_BUSES = {
	Lindholmen: true,
	Frösunda: true,
	Ekskogen: true,
	Kårsta: true,
};

const BUS_TYPES_SWEDISH = {
	"Volvo-dd-blue": "Volvo dubbeldäckare",
	"BYD-el-red": "BYD elbuss",
	"Volvo-boggi-red": "Volvo boggi",
	"Volvo-boggi-blue": "Volvo boggi",
	"Volvo-normal-red": "Volvo kortbuss",
	"VDL-normal-red": "VDL kortbuss",
	"Scania-led-red": "Scania ledbuss",
	"MAN-led-red": "MAN ledbuss",
	"MAN-led-blue": "MAN ledbuss",
};

const LINES = ["L27", "L28", "L29"];

const reasonsData = ["Yttre", "Infra", "SLF", "TDT", "TDF"];

const ORDER_TYPES = ["Pendel", "Enkel"];

type ReplacementTripStatus =
	| "ordered"
	| "ongoing"
	| "complete"
	| "part-complete"
	| "cancelled"
	| "denied"
	| "ended";

type ReplacementTripVehicle = { vehicle: string; start: string; end: string };

interface ReplacementTripType {
	orderTimestamp: string;
	reason: string;
	trainNumber: number;
	type: string;
	busesOrdered: number;
	scheduledDepartureTime: string;
	stations: string[];
	comment: string;
	vehicles: ReplacementTripVehicle[];
	eta: string;
	ata: string;
	atd: string;
	status: ReplacementTripStatus;
	line: string;
	id: number;
}

const filledVehicles = (vehicles: ReplacementTripVehicle[], endedTripsNotCounted = true) => {
	return vehicles.filter(
		(vehicle) => vehicle.vehicle !== "" && (!endedTripsNotCounted || vehicle.end === "")
	).length;
};

// Checks if all the stations between two stations are selected and then only selectes the first and last station.
const getPrettyStations = (trip: ReplacementTripType, showOnlyStartEnd = false) => {
	const tripStations = [...trip.stations];
	const lineStations = [...STATIONS].filter((station) => station.lines.includes(trip.line));

	const firstStationIndex = lineStations.findIndex((station) => station.name === tripStations[0]);
	const lastStationIndex = lineStations.findIndex(
		(station) => station.name === tripStations[tripStations.length - 1]
	);

	const allStationsBetweenStartStop = lineStations.slice(firstStationIndex, lastStationIndex + 1);

	if (showOnlyStartEnd || allStationsBetweenStartStop.length === tripStations.length) {
		return `${tripStations[0]} - ${tripStations[tripStations.length - 1]}`;
	}

	return tripStations.join(", ");
};

const createStatus = (trip: ReplacementTripType) => {
	let style = "";
	let status = "";

	switch (trip.status) {
		case "ended":
			status = "Avslutad";
			style = "bg-gray-200";
			break;
		case "cancelled":
			status = "Avbokad";
			style = "bg-red-200";
			break;
		case "denied":
			status = "Nekad";
			style = "bg-red-300";
			break;
		case "complete":
		case "part-complete":
			status = `Pågår - ${filledVehicles(trip.vehicles)}/${trip.busesOrdered}`;
			style = "bg-green-200";
			break;
		case "ongoing":
			status = "Inväntar tillsättning";
			style = "bg-yellow-200";
			break;
		case "ordered":
			status = "Beställd";
			style = "bg-blue-200";
			break;
		default:
			status = "Okänd";
			style = "bg-gray-200";
			break;
	}

	return (
		<p className={"font-semibold px-2 py-1 rounded text-xs dark:text-black " + style}>
			{status}
		</p>
	);
};

const checkIfAllowedVehicle = (trip: ReplacementTripType, newVehicle: string) => {
	// Check if valid vehicle number.
	if (newVehicle === "" || isNaN(parseInt(newVehicle))) {
		toast.error("Ange ett giltigt vagnsnummer");
		return false;
	}

	const busType = busTypes[newVehicle];

	// Validate type and that is real vehicle number.
	if (busType === undefined) {
		toast.error("Ange ett giltigt vagnsnummer");
		return false;
	}

	// Dubbeldäckare får ej användas.
	if (busType.split("-")[1] === "dd") {
		toast.error("Dubbeldäckare får ej användas");
		return false;
	}

	// Check if vehicle is allowed for this line.
	if (
		trip.stations.filter((station) => STATIONS_ONLY_SMALL_BUSES[station]).length > 0 &&
		busType.split("-")[1] === "led"
	) {
		toast.error("Ledbussar får ej användas på denna sträcka");
		return false;
	}

	// Check if vehicle already exists.
	if (trip.vehicles.find((vehicle) => vehicle.vehicle === newVehicle)) {
		toast.error("Vagnsnummer finns redan");
		return false;
	}

	return true;
};

const getDeliveryStatus = (newTrip: ReplacementTripType, endedTripsNotCounted = false) => {
	const vehicles = filledVehicles(newTrip.vehicles, endedTripsNotCounted);

	// If all vehicles are filled, change status to complete.
	if (vehicles === parseInt(newTrip.busesOrdered.toString())) {
		return "complete";
	} else if (vehicles > 0) {
		return "part-complete";
	} else {
		return "ongoing";
	}
};

export const VehicleMap = ({ trip }: { trip: ReplacementTripType }) => {
	return null;
	if (trip.vehicles.length === 0) {
		return null;
	}

	// Fetch vehicle data.
	const vehicles = trip.vehicles;

	return (
		<div className="w-full h-96">
			<MapContainer center={[59.43926662897951, 18.084435863103256]} zoom={11}>
				<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
				{trip.stations.map((station) => {
					const stationData = STATIONS_OBJECT[station];

					console.log(stationData.name);

					return (
						<Circle
							key={stationData.name}
							center={stationData.position}
							radius={70}
							color="darkBlue"
							fillColor="blue"
							fillOpacity={0.8}
						></Circle>
					);
				})}
			</MapContainer>
		</div>
	);
};

const ReplacementTripSmallRow = ({
	trip,
	onClick,
}: {
	trip: ReplacementTripType;
	onClick: (value: ReplacementTripType) => void;
}) => {
	const scheduledDepartureTime = new Date(trip.scheduledDepartureTime).getTime();
	return (
		<div
			className="flex flex-row w-full bg-white dark:bg-stone-950 justify-between cursor-pointer px-4 border-b rounded py-1 border-slate-200 dark:border-stone-900 hover:bg-stone-50 dark:hover:bg-stone-950 ease-out transition-all"
			onClick={() => onClick(trip)}
		>
			<div className="flex flex-row items-center">
				<p className="text-xs text-blue-500 font-bold mr-3">
					{prettyDate(scheduledDepartureTime)} {prettyTime(scheduledDepartureTime)}
				</p>
				<h1 className="font-bold text-sm mr-3">
					{trip.type} - {trip.busesOrdered}st
				</h1>
				<p className="text-xs">{getPrettyStations(trip, true)}</p>
				<p className="text-[10px] mx-2">|</p>
				<p className="text-xs overflow-hidden text-ellipsis whitespace-nowrap pr-10 flex-1">
					{trip.comment ? trip.comment : "Ingen kommentar"}
				</p>
			</div>
			<div className="flex flex-row items-center">{createStatus(trip)}</div>
		</div>
	);
};

const ReplacementTripRow = ({
	trip,
	onClick,
}: {
	trip: ReplacementTripType;
	onClick: (value: ReplacementTripType) => void;
}) => {
	const scheduledDepartureTime = new Date(trip.scheduledDepartureTime).getTime();
	return (
		<div
			className="flex flex-row w-full mb-2 bg-white dark:bg-stone-950 justify-between cursor-pointer px-4 border rounded py-4 border-slate-200 dark:border-stone-900 hover:bg-stone-50 dark:hover:bg-stone-950 ease-out transition-all"
			onClick={() => onClick(trip)}
		>
			<div>
				<p className="text-xs text-blue-500 font-bold">
					{prettyDate(scheduledDepartureTime)} {prettyTime(scheduledDepartureTime)}
				</p>
				<h1 className="font-bold text-lg">
					{trip.type} - {trip.busesOrdered}st
				</h1>
				<div className="flex flex-row items-center">
					<p className="text-xs">{getPrettyStations(trip, true)}</p>
					<p className="text-[10px] mx-2">|</p>
					<p className="text-xs">{trip.comment ? trip.comment : "Ingen kommentar"}</p>
				</div>
			</div>
			<div className="flex flex-row items-center">{createStatus(trip)}</div>
		</div>
	);
};

const Trips = ({
	trips,
	isLoading,
	isError,
	setSelectedOrder,
	sortBy,
	setSortBy,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
	setSelectedOrder: any;
	sortBy: string;
	setSortBy: any;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga hållplatser hittades</p>;
	}

	const sortedTrips = sortRows(trips, sortBy);

	const activeTrips = [];
	const endedTrips = [];

	for (let i = 0; i < sortedTrips.length; i++) {
		const trip = sortedTrips[i];

		if (trip.status === "ended" || trip.status === "cancelled" || trip.status === "denied") {
			endedTrips.push(trip);
		} else {
			activeTrips.push(trip);
		}
	}

	return (
		<div className="flex flex-col w-full h-full">
			<div className="flex flex-col w-full mb-10 flex-1">
				<div className="flex justify-between">
					<h1 className="font-bold text-base">Aktiva beställningar</h1>
					<InputSorter
						sortBy={sortBy}
						setSortBy={setSortBy}
						placeholder="Sortera"
						items={headers}
					/>
				</div>

				{activeTrips.length > 0 ? (
					activeTrips.map((trip) => (
						<ReplacementTripRow key={trip.id} trip={trip} onClick={setSelectedOrder} />
					))
				) : (
					<p className="text-stone-500 text-sm">Inga aktiva beställningar</p>
				)}
			</div>
			<div className="flex flex-col w-full text-sm font-medium max-h-[33vh] overflow-y-auto">
				<div className="flex justify-between">
					<h1 className="font-bold text-base">Avslutade beställningar</h1>{" "}
					{/* <InputSorter
						sortBy={sortBy}
						setSortBy={setSortBy}
						placeholder="Sortera"
						items={headers}
					/> */}
				</div>
				{endedTrips.length > 0 ? (
					endedTrips.map((trip) => (
						<ReplacementTripSmallRow
							key={trip.id}
							trip={trip}
							onClick={setSelectedOrder}
						/>
					))
				) : (
					<p className="text-stone-500 text-sm">Inga avslutade beställningar</p>
				)}
			</div>
		</div>
	);
};

export default function ReplacementTrips() {
	const params: any = useParams();
	const [_, navigate] = useLocation();

	const { data, mutate, isLoading, isError } = useFetch("replacementTrips");
	const [showModal, setModal] = useState(false);

	const [selectedTripOrder, setSelectedTripOrder] = useState<null | ReplacementTripType>(null);

	const [sortBy, setSortBy] = usePersistentState("vassdupp-sort-rt", "status-asc");

	// States for all inputs
	const [reason, setReason] = useState("");
	const [trainNumber, setTrainNumber] = useState("");
	const [type, setType] = useState("");
	const [busesOrdered, setBusesOrdered] = useState("");
	const [scheduledDepartureTime, setScheduledDepartureTime] = useState(new Date());
	const [line, setLine] = useState("");
	const [stations, setStations] = useState<any>([]);
	const [comment, setComment] = useState("");
	const [direction, setDirection] = useState<"north" | "south">("north");

	useEffect(() => {
		if (params.id && data) {
			const trip = data.find((trip) => trip.id === parseInt(params.id));

			if (trip) {
				setSelectedTripOrder(trip);
				navigate(`/replacementtrips`);
			}
		}
	}, [params.id, data]);

	// States for vehicle input
	const [newVehicle, setNewVehicle] = useState("");

	const handleSetNewVehicle = (value: string) => {
		if (value.length < 5) {
			setNewVehicle(value);
		} else {
			toast.error("Ange ett giltigt vagnsnummer");
		}
	};

	// Handlers for form submission and cancel
	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		// Add logic to handle form submission

		// Validate input.
		if (!type || !busesOrdered || !scheduledDepartureTime || !stations || !line) {
			toast.error("Fyll i alla fält");
			return;
		}

		// If stations does not contain any values, return bad request.
		if (stations.length < 2 || stations.filter((station) => station !== "").length < 2) {
			toast.error("Välj minst två station");
			return;
		}

		const newTrip = {
			orderTimestamp: new Date(),
			reason,
			trainNumber,
			type,
			busesOrdered,
			scheduledDepartureTime,
			line,
			stations,
			comment,
			vehicles: [],
			status: "ordered",
		};

		mutate([...data, { ...newTrip, id: "TEMPELITION" }], { revalidate: false });
		handleCancel();

		toast.promise(
			postman.post("replacementTrips", newTrip).then((response) => {
				mutate(
					(newData) => {
						const createdTrip = response.data;
						const updatedData = [...newData].map((trip) => {
							if (trip.id === "TEMPELITION") {
								return { ...trip, id: createdTrip.id };
							}

							return trip;
						});

						return updatedData;
					},
					{ revalidate: false }
				);
			}),
			{
				loading: "Skapar beställning...",
				success: "Beställning skapad",
				error: "Något gick fel",
			}
		);
	};

	const handleCancel = () => {
		// Reset all states
		setReason("");
		setTrainNumber("");
		setType("");
		setBusesOrdered("");
		setLine("");
		setStations([]);
		setComment("");
		setModal(false);
	};

	const updateTrip = (newTrip) => {
		toast.promise(postman.put(`replacementTrips?id=${selectedTripOrder.id}`, newTrip), {
			loading: "Uppdaterar beställning...",
			success: "Beställning uppdaterad",
			error: "Något gick fel",
		});

		setSelectedTripOrder(newTrip);
	};

	const addVehicle = () => {
		// Check if allowed vehicle.
		const allowed = checkIfAllowedVehicle(selectedTripOrder, newVehicle);

		if (!allowed) {
			return;
		}

		const newTrip = { ...selectedTripOrder };

		newTrip.vehicles.push({ vehicle: newVehicle, start: "", end: "" });

		newTrip.status = getDeliveryStatus(newTrip);

		const newTrips = [...data].map((trip) => {
			if (trip.id === selectedTripOrder.id) {
				return newTrip;
			}

			return trip;
		});

		mutate(newTrips, { revalidate: false });

		updateTrip(newTrip);
		setNewVehicle("");
	};

	const removeVehicle = (index: number) => {
		const newTrip = { ...selectedTripOrder };

		newTrip.vehicles.splice(index, 1);

		newTrip.status = getDeliveryStatus(newTrip);

		const newTrips = [...data].map((trip) => {
			if (trip.id === selectedTripOrder.id) {
				return newTrip;
			}

			return trip;
		});

		mutate(newTrips, { revalidate: false });

		updateTrip(newTrip);
	};

	const handleChangeVehicleTime = (index: number, type: "start" | "end") => {
		const newTrip = { ...selectedTripOrder };

		newTrip.vehicles[index][type] = new Date().toISOString();

		newTrip.status = getDeliveryStatus(newTrip);

		const newTrips = [...data].map((trip) => {
			if (trip.id === selectedTripOrder.id) {
				return newTrip;
			}

			return trip;
		});

		mutate(newTrips, { revalidate: false });

		updateTrip(newTrip);
	};

	// Change status of order
	const changeOrderStatus = (status: ReplacementTripStatus) => {
		const newTrip = { ...selectedTripOrder };

		newTrip.status = status;

		// If status is ended, set all vehicles to end time.
		if (status === "ended") {
			newTrip.vehicles = newTrip.vehicles.map((vehicle) => {
				if (vehicle.start === "") {
					vehicle.start = new Date().toISOString();
				}

				if (vehicle.end === "") {
					vehicle.end = new Date().toISOString();
				}

				return vehicle;
			});
		}

		const newTrips = [...data];

		const tripIndex = newTrips.findIndex((trip) => trip.id === selectedTripOrder.id);

		newTrips[tripIndex] = newTrip;

		mutate(newTrips, { revalidate: false });

		updateTrip(newTrip);
	};

	return (
		<div className="flex flex-1 w-full h-full flex-col">
			<Modal show={showModal} closeModal={() => setModal(false)}>
				<form
					className="flex flex-col justify-between w-full h-full items-center py-8 px-16 max-h-screen overflow-y-scroll space-y-3"
					onSubmit={handleSubmit}
				>
					<div className="flex flex-col items-center mb-6">
						<h1 className="font-medium text-xl">Skapa ersättningstur</h1>
						<p className="mt-2 text-slate-500 text-sm">
							Fyll i fälten nedan för att skapa en ersättningstur
						</p>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Typ av beställning"
							value={type}
							onChange={setType}
							items={ORDER_TYPES.map((type) => [type, type])}
						/>
					</div>
					<div className="w-full">
						<Input
							placeholder="Antal bussar"
							value={busesOrdered}
							onChange={setBusesOrdered}
							type="number"
						/>
					</div>
					<div className="relative select-none w-full">
						<InputSelect
							placeholder="Linje"
							value={line}
							onChange={setLine}
							items={LINES.map((line) => [line, line])}
						/>
					</div>
					{line !== "" ? (
						<div className="w-full relative">
							<div className="w-full flex flex-row justify-between items-center mb-3">
								<p className="font-semibold text-sm">Riktning:</p>
								<div
									className="bg-slate-100 dark:bg-stone-900 select-none min-w-36 px-2 py-1 flex justify-between text-sm rounded text-center font-medium cursor-pointer hover:opacity-60 dark:text-black"
									onClick={() =>
										setDirection(direction === "north" ? "south" : "north")
									}
								>
									<p>{direction === "north" ? "Nordlig" : "Södergående"}</p>
									<span className="">{direction === "north" ? "↑" : "↓"}</span>
								</div>
							</div>
							<div className="w-full">
								<select
									className="border rounded w-full px-1 pt-2 min-h-80 text-sm"
									multiple
									value={stations}
									onChange={(e: any) =>
										setStations(
											Array.from(e.target.selectedOptions).map(
												(option: any) => option.value
											)
										)
									}
								>
									{(direction === "north" ? STATIONS : [...STATIONS].reverse())
										.filter((station) => station.lines.includes(line))
										.map((station, i) => (
											<option key={station.name} value={station.name}>
												{station.name}
											</option>
										))}
								</select>
							</div>
						</div>
					) : null}

					<InputDateTime
						value={scheduledDepartureTime}
						onChange={setScheduledDepartureTime}
						placeholder="Avgångstid"
					/>

					<div className="w-full">
						<Input placeholder="Tågnr" value={trainNumber} onChange={setTrainNumber} />
					</div>
					<div className="w-full">
						<InputSelect
							value={reason}
							onChange={setReason}
							placeholder="Orsak"
							items={reasonsData.map((reason) => [reason, reason])}
						/>
					</div>
					<div className="w-full">
						<Input placeholder="Kommentar" value={comment} onChange={setComment} />
					</div>

					<div className="flex flex-row justify-between space-x-2 items-center">
						<div
							className="bg-slate-200 px-4 py-2 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black"
							onClick={() => {
								handleCancel();
							}}
						>
							Avbryt
						</div>
						<input
							type="submit"
							className="bg-blue-200 px-4 py-2 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black dark:bg-blue-400"
							value="Skapa beställning"
						/>
					</div>
				</form>
			</Modal>

			<Modal show={selectedTripOrder !== null} closeModal={() => setSelectedTripOrder(null)}>
				{selectedTripOrder ? (
					<div className="flex flex-col md:w-[60vw] max-w-[90vw] w-[100vw] py-12 px-8">
						<div className="flex flex-row justify-between items-center">
							<p className="font-semibold text-lg">
								{selectedTripOrder.type} - {selectedTripOrder.busesOrdered} bussar
							</p>
							<div className="ml-auto">{createStatus(selectedTripOrder)}</div>
						</div>
						<h1 className="font-semibold mb-4 text-sm">
							Beställd avgångstid:{" "}
							{prettyDate(
								new Date(selectedTripOrder.scheduledDepartureTime).getTime()
							)}{" "}
							{prettyTime(
								new Date(selectedTripOrder.scheduledDepartureTime).getTime()
							)}
						</h1>
						<div className="mb-4">
							<h1 className="font-semibold text-sm mb-1">Stationer</h1>
							<p className="text-sm">{getPrettyStations(selectedTripOrder)}</p>
						</div>
						{selectedTripOrder.comment ? (
							<span className="mb-4">
								<span className="font-semibold">Kommentar: </span>
								{selectedTripOrder.comment}
							</span>
						) : null}

{/* 						<div className="relative h-[40vh] w-full">
							<MapContainer
								center={[59.45137947357416, 18.083999103309054]}
								zoom={12}
								className="z-0 h-full w-full"
							>
								<TileLayer
									url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								/>
								{selectedTripOrder.stations.map((station, i) => {
									const stationData = STATIONS_OBJECT[station];
									const nextStationData =
										STATIONS_OBJECT[
											selectedTripOrder.stations[
												selectedTripOrder.stations.indexOf(station) + 1
											]
										];

									return (
										<>
											{
												// Add a line between the markers, except for the last one
												i < selectedTripOrder.stations.length - 1 && (
													<Polyline
														positions={[
															stationData.position,
															nextStationData.position,
														]}
														opacity={1}
														color="darkBlue"
													/>
												)
											}
											<DynamicCircle
												key={stationData.name}
												center={stationData.position}
												radius={70}
												color="darkBlue"
												fillOpacity={0.8}
											></DynamicCircle>
										</>
									);
								})}
							</MapContainer>
						</div> */}

						{selectedTripOrder.status === "ongoing" ||
						selectedTripOrder.status === "part-complete" ||
						selectedTripOrder.status === "complete" ? (
							<div>
								<VehicleMap trip={selectedTripOrder} />

								<div className="flex flex-col mt-3 w-full">
									<p className="font-semibold text-sm mb-1">Fordon:</p>
									{getDeliveryStatus(selectedTripOrder, true) !== "complete" ? (
										<div className="flex flex-col items-center mt-4">
											<div className="w-full justify-between items-center flex space-x-2 relative">
												<Input
													value={newVehicle}
													onChange={handleSetNewVehicle}
													placeholder="Vagnsnummer"
												/>

												{BUS_TYPES_SWEDISH[busTypes[newVehicle]] ? (
													<p className="absolute right-40 text-sm text-stone-600">
														({BUS_TYPES_SWEDISH[busTypes[newVehicle]]})
													</p>
												) : null}

												<button
													onClick={() => addVehicle()}
													className="bg-blue-200 dark:bg-blue-900 whitespace-nowrap px-5 py-3 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black text-sm"
												>
													Lägg till fordon
												</button>
											</div>
										</div>
									) : null}
									<div className="flex flex-col items-center mt-4 space-y-3">
										{selectedTripOrder.vehicles.map((vehicle, i: number) => (
											<div
												key={"et-v" + vehicle.vehicle}
												className="w-full justify-between items-center flex space-x-1 relative"
											>
												<div className="flex-1 flex flex-row justify-start bg-slate-100 dark:bg-stone-900 h-11 rounded pl-2 py-2.5 font-medium">
													<p className="flex font-medium">
														{vehicle.vehicle}
													</p>
													<p className="ml-2 mt-0.5 flex font-medium text-stone-500 text-sm">
														(
														{
															BUS_TYPES_SWEDISH[
																busTypes[vehicle.vehicle]
															]
														}
														)
													</p>
													{vehicle.start !== "" ? (
														<p className="ml-2 mt-0.5 flex font-medium text-stone-500 text-sm">
															{prettyTime(
																new Date(vehicle.start).getTime()
															)}
															{vehicle.end !== "" ? (
																<>
																	{" "}
																	-{" "}
																	{prettyTime(
																		new Date(
																			vehicle.end
																		).getTime()
																	)}
																</>
															) : null}
														</p>
													) : null}
												</div>
												{vehicle.end === "" ? (
													<div>
														{vehicle.start === "" ? (
															<button
																onClick={() =>
																	handleChangeVehicleTime(
																		i,
																		"start"
																	)
																}
																className="bg-blue-200 whitespace-nowrap px-4 py-3 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black text-sm"
															>
																Påbörja
															</button>
														) : (
															<button
																onClick={() =>
																	handleChangeVehicleTime(
																		i,
																		"end"
																	)
																}
																className="bg-green-200 whitespace-nowrap px-4 py-3 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black text-sm"
															>
																Avsluta
															</button>
														)}
													</div>
												) : null}
												<button
													onClick={() => removeVehicle(i)}
													className="hover:text-red-700 absolute -right-8 py-2 px-2 -top-1 text-lg rounded font-bold cursor-pointer hover:opacity-80 dark:text-black"
												>
													✕
												</button>
											</div>
										))}
									</div>
								</div>
							</div>
						) : null}
						{selectedTripOrder.status === "ended" ? (
							<div>
								<p className="font-semibold text-sm mb-1">Fordon:</p>{" "}
								<div className="flex flex-col flex-wrap mt-2">
									{selectedTripOrder.vehicles.map((veh) => (
										<div
											className="flex-1 flex flex-row justify-between bg-slate-100 dark:bg-stone-900 h-11 rounded px-2 mb-1 items-center py-2.5 font-medium"
											key={veh.vehicle}
										>
											<div className="flex items-center">
												<p className="flex font-medium min-w-10">
													{veh.vehicle}
												</p>
												<p className="ml-1 flex font-medium text-stone-500 text-xs">
													({BUS_TYPES_SWEDISH[busTypes[veh.vehicle]]})
												</p>
											</div>

											<p className="flex font-medium  text-xs">
												{prettyTime(new Date(veh.start).getTime())} -{" "}
												{prettyTime(new Date(veh.end).getTime())}
											</p>
										</div>
									))}
								</div>
							</div>
						) : null}

						<div className="flex justify-center space-x-2 mt-8 mb-2">
							{selectedTripOrder?.status === "ordered" ? (
								<div className="flex flex-col justify-center items-center">
									<button
										className="bg-green-200 px-4 text-sm py-2 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black"
										onClick={() => changeOrderStatus("ongoing")}
									>
										Acceptera
									</button>
									<p className="text-xs text-stone-600">(Görs av Buss)</p>
								</div>
							) : null}

							{selectedTripOrder?.status === "ordered" ||
							selectedTripOrder?.status === "ongoing" ? (
								<div className="flex flex-col justify-center items-center">
									<button
										className="bg-red-300 px-4 text-sm py-2 rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black"
										onClick={() => changeOrderStatus("denied")}
									>
										Neka beställning
									</button>
									<p className="text-xs text-stone-600">(Görs av Buss)</p>
								</div>
							) : null}
						</div>

						<div className="flex justify-center space-x-2 mt-2">
							{selectedTripOrder?.status === "ordered" ||
							selectedTripOrder.status === "ongoing" ? (
								<div className="flex flex-col justify-center items-center">
									<button
										className="bg-red-200 px-4 py-2 text-sm rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black"
										onClick={() => changeOrderStatus("cancelled")}
									>
										Avboka beställning
									</button>
									<p className="text-xs text-stone-600">(Görs av RB)</p>
								</div>
							) : null}

							{selectedTripOrder?.status === "part-complete" ||
							selectedTripOrder.status === "complete" ? (
								<button
									className="bg-gray-200 px-4 py-2 text-sm rounded font-semibold cursor-pointer hover:opacity-80 dark:text-black"
									onClick={() => changeOrderStatus("ended")}
								>
									Avsluta beställning
								</button>
							) : null}
						</div>
					</div>
				) : null}
			</Modal>

			<h1 className="font-bold text-2xl mt-12 mb-3">Ersättningstrafik</h1>
			<div className="flex justify-between mt-4 mb-4">
				<div
					className={
						"bg-blue-200 self-start text-sm px-4 py-2 rounded font-semibold cursor-pointer hover:opacity-80 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700 "
					}
					onClick={() => setModal(true)}
				>
					Skapa beställning
				</div>
			</div>
			<div className="flex flex-1 flex-col w-full">
				<Trips
					trips={data}
					isLoading={isLoading}
					isError={isError}
					setSelectedOrder={setSelectedTripOrder}
					sortBy={sortBy}
					setSortBy={setSortBy}
				/>
			</div>
		</div>
	);
}
