const TableHeader = (props: {
	items: { name: string; width: string; key?: string }[];
	sortBy?: string;
	setSortBy?: (sortBy: string) => void;
}) => {
	const [sortBy, sortOrder] = props.sortBy ? props.sortBy.split("-") : ["", ""];

	return (
		<div className="flex flex-row w-full items-center rounded bg-blue-50 dark:bg-blue-950  px-4 py-3 select-none">
			{props.items.map((item, index) => (
				<p
					key={index}
					className={`font-bold cursor-pointer text-xs hover:opacity-70 text-blue-600 dark:text-blue-400 opacity-70 ${item.width}`}
					onClick={() =>
						props.setSortBy(item.key + (sortOrder === "desc" ? "-asc" : "-desc"))
					}
				>
					{item.name}{sortBy === item.key ? (sortOrder === "asc" ? " ▲" : " ▼") : ""}
				</p>
			))}
		</div>
	);
};

export default TableHeader;
