import ContractSelector from "../../components/contractSelector";
import InputFile from "../../components/inputFile";
import Modal from "../../components/modal";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { STATUS_TITLE_MAP } from "../../config";
import TRIP_TRAFFIC_AREA_OWBER from "../../data/lines";
import { keysToLowerCaseArray } from "../../helper/array";
import { getYearMonthFormat } from "../../helper/date";
import { parseJSON } from "../../helper/json";
import postman from "../../helper/postman";

import useFetch from "../../helper/swr";
import { useMemo, useState } from "react";
import toast from "react-hot-toast";
import usePersistentState from "../../helper/usePersistentState";

/* 

Trip data:
OperatingDay
Line
Trip
PlannedDistance
PlannedTime
ActualDistance
ActualTime
StartStop
EndStop
DeviationStartStop
Comment
Contract

*/

const HEADER_NAMES = [
	{ name: "Avtal", width: "w-12" },
	{ name: "Datum", width: "w-20" },
	{ name: "Linje", width: "w-20" },
	{ name: "Tur", width: "w-20" },
	{ name: "Planerad distans", width: "w-32" },
	{ name: "Planerad tid", width: "w-32" },
	{ name: "Faktisk tid", width: "w-32" },
	{ name: "Faktisk distans", width: "w-32" },
	{ name: "Start hpl", width: "w-36" },
	{ name: "Slut hpl", width: "w-40" },
	{ name: "Avvikelse hpl", width: "w-40" },
	{ name: "Kommentar", width: "flex-1" },
];

const VALID_TRIPS_7 = {
	574: "odensala skola",
	632: "köpmanholms skola",
	649: "beatebergs vägskäl",
	634: "bromskärs brygga",
	636: "björkö-arholma skola",
};

const VALID_TRIPS_8 = {
	574: "odensala skola",
	649: "beateberg",
};

export function TripRow({
	trip,
}: {
	trip: {
		contract: string;
		operatingDay: string;
		line: string;
		trip: string;
		plannedDistance: number;
		plannedTime: number;
		actualDistance: number;
		actualTime: number;
		startStop: string;
		endStop: string;
		deviationStartStop: string;
		comment: string;
	};
}) {
	return (
		<div className="flex flex-row w-full items-center px-4 py-2 border-b border-slate-200 dark:border-stone-900 hover:bg-stone-50 ease-out transition-all">
			<p className="font-semibold text-sm w-12">{trip.contract}</p>
			<p className="font-semibold text-sm w-20">{trip.operatingDay}</p>
			<div className="flex w-20">
				<p className={"font-semibold text-sm px-1.5 rounded bg-slate-200"}>{trip.line}</p>
			</div>
			<p className="font-semibold text-sm w-20">{trip.trip}</p>
			<p className="font-semibold text-sm w-32">
				{Math.round(trip.plannedDistance / 1000)}km
			</p>
			<p className="font-semibold text-sm w-32">{trip.plannedTime}min</p>
			<p className="font-semibold text-sm w-32">{trip.actualTime}min</p>
			<p className="font-semibold text-sm w-32">{Math.round(trip.actualDistance / 1000)}km</p>
			<p className="font-semibold text-sm w-36">{trip.startStop}</p>
			<p className="font-semibold text-sm w-40">{trip.endStop}</p>
			<p className="font-semibold text-sm w-40">{trip.deviationStartStop}</p>
			<p className="font-semibold text-sm flex-1">{trip.comment}</p>
		</div>
	);
}

const FILTER_MAP = {};

const Trips = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	const filteredTrips = trips.filter((trip: any) => FILTER_MAP[trip.status] === undefined);

	return (
		<div className="flex flex-1 w-full flex-col">
			{filteredTrips.map((trip, i) => (
				<TripRow key={"dr-" + i} trip={trip} />
			))}
		</div>
	);
};

const calculateStatuses = (trips: any) => {
	const TYPES_OF_STATUS_VALUES = {};
	const TYPES_OF_STATUS_KEYS = [];

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];
		if (!TYPES_OF_STATUS_VALUES[trip.status]) {
			TYPES_OF_STATUS_VALUES[trip.status] = 1;
			TYPES_OF_STATUS_KEYS.push(trip.status);
		} else {
			TYPES_OF_STATUS_VALUES[trip.status]++;
		}
	}

	return [TYPES_OF_STATUS_KEYS, TYPES_OF_STATUS_VALUES];
};

const StatBox = ({
	title,
	procentage,
	value,
}: {
	title: string;
	procentage: string;
	value: number | string;
}) => {
	return (
		<div className="flex flex-col flex-1 mx-1 mb-1 text-center bg-slate-100 px-10 py-4 justify-center items-center max-w-40">
			<h4 className="font-semibold text-2xl">{value}</h4>
			<p>{procentage}</p>
			<p>{title}</p>
		</div>
	);
};

const TripsHeader = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	const heavyResult: any = useMemo(() => {
		if (isLoading || isError || trips === undefined || trips?.length === 0) {
			return [];
		}

		return calculateStatuses(trips);
	}, [trips, isLoading, isError]);

	if (heavyResult.length === 0) {
		return <p></p>;
	}

	return (
		<div className="flex flex-1 w-full flex-row mb-6">
			{heavyResult[0].map((status, i) => (
				<StatBox
					key={"stat-" + i}
					title={STATUS_TITLE_MAP[status]}
					procentage={((heavyResult[1][status] / trips.length) * 100).toFixed(2) + "%"}
					value={heavyResult[1][status]}
				/>
			))}
		</div>
	);
};

export default function PlannedDeviatedTrips() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
	} = useFetch(`planneddeviatedtrips?contract=${contract}&month=${month}`);

	const [showModal, setModal] = useState(false);

	const handleAddTrips = (file) => {
		const reader = new FileReader();
		reader.onload = (event: any) => {
			// Parse data
			const bstr = event.target.result;

			const trip_calls_promise = parseJSON(bstr);

			if (!trip_calls_promise || trip_calls_promise.length === 0) {
				alert("Saknas turer");
				return;
			}

			const trip_calls = keysToLowerCaseArray(trip_calls_promise);
			const grouped_trips = {};
			const trip_keys = [];

			for (let i = 0; i < trip_calls.length; i++) {
				const call = trip_calls[i];
				const operatingDay = call.operatingCalendarDay.split("T")[0].replaceAll("-", "");
				const line = call.lineNameLong;
				const trip: string = call.journeyName;
				const key = `${operatingDay}-${line}-${trip}`;

				if (VALID_TRIPS_7[line] === undefined && VALID_TRIPS_8[line] === undefined) {
					continue;
				}

				if (!grouped_trips[key]) {
					trip_keys.push(key);

					grouped_trips[key] = {
						operatingDay,
						line,
						trip,
						stops: [],
						totalDistance: 0,
						actualDistance: 0,
						totalTime: 0,
						actualTime: 0,
						actualStop: null,
					};
				}

				grouped_trips[key].stops.push({
					distance: call.measuredDistanceToNextPointInJourney ?? 0,
					plannedTime: new Date(call.plannedArrivalTime ?? call.plannedDepartureTime),
					sequence: call.sequenceInJourney,
					name: call.stopPointName,
				});
			}

			for (let i = 0; i < trip_keys.length; i++) {
				const trip = grouped_trips[trip_keys[i]];
				const key = `${trip.operatingDay}-${trip.line}-${trip.trip}`;
				const directionOne = trip.trip.charAt(0) === "7";
				const valid_trips = directionOne ? VALID_TRIPS_7 : VALID_TRIPS_8;

				let totalDistance = 0;
				let distanceToRemove = 0;

				// Order the stops
				grouped_trips[key].stops = trip.stops.sort((a, b) =>
					directionOne ? a.sequence - b.sequence : b.sequence - a.sequence
				);

				// Caluclate total distance.
				for (let j = 0; j < trip.stops.length; j++) {
					const stop = trip.stops[j];
					totalDistance += stop.distance;
				}

				// Set total distance.
				grouped_trips[key].totalDistance = totalDistance;

				// Set total time.
				grouped_trips[key].totalTime =
					Math.abs(
						trip.stops[trip.stops.length - 1].plannedTime.getTime() -
							trip.stops[0].plannedTime.getTime()
					) / 60000;

				for (let j = trip.stops.length - 1; j >= 0; j--) {
					const stop = trip.stops[j];
					distanceToRemove += stop.distance;

					if (stop.actualTime) {
						grouped_trips[key].actualStop = stop;
						break;
					}

					if (stop.name.toLowerCase().includes(valid_trips[trip.line])) {
						grouped_trips[key].actualStop = stop;
						break;
					}
				}

				grouped_trips[key].actualDistance = totalDistance - distanceToRemove;

				if (!grouped_trips[key].actualStop) {
					console.log("No stop found", key, grouped_trips[key]);
				}

				// Calculate actual time
				grouped_trips[key].actualTime =
					grouped_trips[key].totalTime -
					Math.abs(
						trip.stops[trip.stops.length - 1].plannedTime.getTime() -
							grouped_trips[key].actualStop.plannedTime.getTime()
					) /
						60000;

				// Change the order back if reversed.
				if (!directionOne) {
					grouped_trips[key].stops = grouped_trips[key].stops.reverse();
				}
			}

			const data = Object.values(grouped_trips).map((trip: any) => {
				return {
					operatingDay: trip.operatingDay,
					line: trip.line,
					trip: trip.trip,
					plannedDistance: trip.totalDistance,
					plannedTime: trip.totalTime,
					actualTime: trip.actualTime,
					actualDistance: trip.actualDistance,
					deviationStartStop: trip.actualStop.name,
					startStop: trip.stops[0].name,
					endStop: trip.stops[trip.stops.length - 1].name,
					contract: TRIP_TRAFFIC_AREA_OWBER[trip.line],
					comment: "",
				};
			});

			toast.promise(postman.post(`planneddeviatedtrips`, data), {
				loading: `Lägger till ${data.length}st turer...`,
				success: `${data.length}st turer har lagts till`,
				error: "Ett fel uppstod",
			});
		};
		reader.readAsBinaryString(file);
	};

	return (
		<div className="flex flex-1 px-6 flex-col">
			<Modal show={showModal} closeModal={() => setModal(false)}>
				<div className="flex flex-col justify-between w-full min-w-96 h-full items-center py-12 px-32">
					<h2 className="mb-6 font-medium">Lägg till fil</h2>
					<InputFile name="trips" accept=".json" onHandleFile={handleAddTrips} />
				</div>
			</Modal>
			<div className="flex w-full justify-between items-center">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Planerade avvikelser</h1>
					{trips ? (
						<h1 className="font-semibold text-sm text-gray-700">
							{trips.length}st turer under period
						</h1>
					) : null}
					<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
						Alla turer som har godkända planerade avvikelser
					</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<div className="flex">
						<MonthSelector value={month} onChange={setMonth} />
						<ContractSelector value={contract} onChange={setContract} />
					</div>
					{/* <p
						className="underline mt-6 text-sm text-blue-500 font-medium cursor-pointer"
						onClick={() => setModal(true)}
					>
						Lägg till turer
					</p> */}
				</div>
			</div>

			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={HEADER_NAMES} />
				<Trips trips={trips} isLoading={isLoadingTrips} isError={isTripError} />
			</div>
		</div>
	);
}
