export const StatBox = ({
	title,
	procentage,
	value,
	appendedix = "st",
}: {
	title: string;
	procentage: string;
	value: number | string;
	appendedix?: string;
}) => {
	return (
		<div className="flex flex-col flex-1 mx-1 mb-1 bg-blue-50 rounded dark:bg-blue-950 dark:text-blue-400 px-3 py-2">
			<p className="whitespace-nowrap text-sm font-medium">{title}</p>
			<span className="flex items-end font-semibold text-sm text-blue-600">
				<h4 className="font-semibold text-3xl">{procentage}</h4>
				{appendedix !== "st" ? "" : "%"}
			</span>
			<p className="text-base font-medium mt-2">
				{value}
				{appendedix}
			</p>
		</div>
	);
};
