export default function InputSorter({
	sortBy,
	setSortBy,
	placeholder,
	items,
}: {
	sortBy: string;
	setSortBy: any;
	placeholder: string;
	items: { key: string; name: string; width?: string }[];
}) {
	return (
		<div className="relative select-none">
			<select
				className="bg-stone-100 text-right text-sm py-1.5 px-2 appearance-none cursor-pointer dark:bg-stone-900 rounded  dark:text-stone-200 dark:placeholder:text-stone-400"
				onChange={(e) => setSortBy(e.target.value)}
				value={sortBy}
			>
				<option disabled value="">
					{placeholder}
				</option>
				{items.map(({ key, name }) =>
					["asc", "desc"].map((order) => (
						<option key={key + order} value={key + "-" + order}>
							{name} {order === "asc" ? " stigande ▲" : " fallande ▼"}
						</option>
					))
				)}
			</select>
		</div>
	);
}
