import { useState } from "react";
import { zeroed } from "../helper/date";
import Input from "./input";

const InputDateTime = ({
	value,
	onChange,
	placeholder,
}: {
	value: Date;
	onChange: (value: any) => void;
	placeholder: string;
}) => {
	const handleDateChange = (newValue) => {
		const [year, month, day] = newValue.split("-");
		const date = new Date(value);

		date.setFullYear(year);
		date.setMonth(month - 1);
		date.setDate(day);

		onChange(date);
	};

	const handleTimeChange = (newValue) => {
		const [hour, minute] = newValue.split(":");
		const date = new Date(value);

		date.setHours(hour);
		date.setMinutes(minute);
		date.setSeconds(0);
		date.setMilliseconds(0);

		onChange(date);
	};

	const date = `${new Date(value).toISOString().split("T")[0]}`;
	const time = `${zeroed(new Date(value).getHours())}:${zeroed(new Date(value).getMinutes())}`;

	return (
		<div className="w-full flex-row flex text-sm">
			<div className="flex flex-1 w-1/2 mr-1">
				<Input
					placeholder={placeholder}
					value={date}
					type="date"
					onChange={handleDateChange}
				/>
			</div>
			<div className="flex flex-1 w-1/2">
				<Input placeholder="" value={time} type="time" onChange={handleTimeChange} />
			</div>
		</div>
	);
};

export default InputDateTime;
