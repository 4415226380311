import TableHeader from "../../components/tableHeader";
import { sortRows } from "../../helper/sorter";
import useFetch from "../../helper/swr";
import usePersistentState from "../../helper/usePersistentState";

const HEADER_NAMES = [
	{ key: "type", name: "Typ", width: "w-36" },
	{ key: "description", name: "Beskrivning", width: "w-36" },
	{ key: "operatingDay", name: "Datum", width: "w-36" },
	{ key: "line", name: "Linje", width: "w-28" },
	{ key: "trip", name: "Tur", width: "w-28" },
	{ key: "vehicle", name: "Fordon", width: "w-36" },
	{ key: "comment", name: "Kommentar", width: "flex-1" },
	{ key: "status", name: "Status", width: "w-36" },
];

const Rows = ({ rows, isLoading, isError, sortBy }) => {
	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (isError) {
		return <p>Error...</p>;
	}

	const sortedRows = sortRows(rows, sortBy);

	return (
		<div className="flex flex-1 flex-col w-full">
			{sortedRows.map((row) => (
				<div key={row.trip} className="flex flex-row w-full border-b border-gray-200">
					<div className="flex flex-row w-full">
						<p className="w-36">{row.type}</p>
						<p className="w-36">{row.description}</p>
						<p className="w-36">{row.operatingDay}</p>
						<p className="w-28">{row.line}</p>
						<p className="w-28">{row.trip}</p>
						<p className="w-36">{row.vehicle}</p>
						<p className="flex-1">{row.comment}</p>
						<p className="w-36">{row.status}</p>
					</div>
				</div>
			))}
		</div>
	);
};

export default function Overview() {
	const [sortBy, setSortBy] = usePersistentState("vassdupp-track-o-filter", "title-asc");

	const { data: rows, isLoading, isError } = useFetch("trackem");

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<h1 className="mt-12 font-semibold text-2xl">Översikt - Trackem</h1>
			</div>

			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={HEADER_NAMES} setSortBy={setSortBy} sortBy={sortBy} />
				<Rows rows={rows} isLoading={isLoading} isError={isError} sortBy={sortBy} />
			</div>
		</div>
	);
}
