import { MapContainer, TileLayer, Popup, Polygon, useMap, Circle } from "react-leaflet";
import useFetch from "../helper/swr";

import "leaflet/dist/leaflet.css";
import InputSelect from "../components/inputSelect";
import { useEffect, useState } from "react";

type Area = {
	name: string;
	zones: {
		name: string;
		slots?: number;
		points: [number, number][];
	}[];
};

const DEPOT_MAP = {
	taga: "Täby",
	noga: "Norrtälje",
	gaga: "Gävle",
	maga: "Märsta",
};

const DEPOT_MAP_REVERSE = {
	täga: "taga",
	noga: "noga",
	gäga: "gaga",
	mäga: "maga",
};

const GeoZones = ({ areas, showZones }: { areas: Area[]; showZones: boolean }) => {
	if (!areas || areas.length === 0 || !showZones) {
		return null;
	}

	return (
		<>
			{areas.map((area) => {
				return area.zones.map((geozone) => {
					return (
						<Polygon
							key={`${area.name}-${geozone.name}`}
							pathOptions={{ color: geozone.name.length === 1 ? "red" : "blue" }}
							positions={geozone.points}
						>
							<Popup>
								<div>
									<p>{geozone.name}</p>
									<p>{geozone.slots}</p>
								</div>
							</Popup>
						</Polygon>
					);
				});
			})}
		</>
	);
};

const DepotSelectorPopup = ({
	areas,
	depot,
	setDepot,
}: {
	areas: Area[];
	depot: string;
	setDepot: any;
}) => {
	if (!areas || areas.length === 0) {
		return null;
	}

	return (
		<InputSelect
			placeholder="Depå"
			items={areas.map((area) => [area.name, DEPOT_MAP[area.name]])}
			onChange={(value) => setDepot(value)}
			value={depot}
		/>
	);
};

const TopRightPop = ({ children }: { children: any }) => {
	return (
		<div className="absolute top-0 right-0 rounded mt-2 mr-2 z-10 py-4 px-4 bg-white dark:bg-stone-950 shadow-lg">
			{children}
		</div>
	);
};

const GetActiveDepot = ({ areas, depot }: { areas: Area[]; depot: string }): any => {
	const map = useMap();

	useEffect(() => {
		if (areas && areas.length !== 0) {
			const activeDepot = areas.find((area) => area.name === depot);
			const correctZone = activeDepot?.zones.find((zone) => {
				return DEPOT_MAP_REVERSE[zone.name.toLowerCase()] === depot;
			});

			if (correctZone) {
				const center = correctZone.points[0];
				map.setView(center);
			}
		}
	}, [areas, depot]);

	return null;
};

const GetClick = ({ onClick }: { onClick: any }) => {
	const map = useMap();

	map.on("click", (e) => {
		onClick([e.latlng.lat, e.latlng.lng]);
	});

	return null;
};

const Vehicles = ({ vehicles }: { vehicles: any }) => {
	if (!vehicles || vehicles.length === 0) {
		return null;
	}

	const list = Object.values(vehicles);

	return (
		<>
			{list.map((vehicle: any) => {
				return (
					<Circle
						key={vehicle.id}
						center={[vehicle.latitude, vehicle.longitude]}
						radius={3}
						fillOpacity={1}
						fillColor="black"
						color="black"
					>
						<Popup>
							<div>
								<p>{vehicle.id}</p>
								<p>{vehicle.name}</p>
							</div>
						</Popup>
					</Circle>
				);
			})}
		</>
	);
};

const BusParkingAdmin = () => {
	const [depot, setDepot] = useState("taga");
	const [clicked, setClicked] = useState<[number, number] | null>(null);
	const [showZones, setShowZones] = useState(true);
	const { data: geoZones } = useFetch("/geozones");
	const { data: vehicles } = useFetch("/vehiclesparking/" + depot);

	return (
		<div className="relative h-screen w-full">
			<MapContainer
				center={[59.45137947357416, 18.083999103309054]}
				zoom={12}
				className="z-0 h-full w-full"
			>
				<TileLayer
					url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				/>
				<GeoZones areas={geoZones} showZones={showZones} />
				<Vehicles vehicles={vehicles} />

				<GetActiveDepot areas={geoZones} depot={depot} />

				<GetClick onClick={setClicked} />
			</MapContainer>
			<TopRightPop>
				<DepotSelectorPopup areas={geoZones} depot={depot} setDepot={setDepot} />
				{clicked ? <p className="mt-5">{JSON.stringify(clicked)}</p> : null}
				<p onClick={() => setShowZones(!showZones)} className="cursor-pointer mt-5">
					{showZones ? "Dölj zoner" : "Visa zoner"}
				</p>
			</TopRightPop>
		</div>
	);
};

export default BusParkingAdmin;
