import { navigate } from "wouter/use-browser-location";
import { getNavigation } from "../helper/navigation";
import { safeUserStorage } from "../helper/storage";
import { Link } from "wouter";
import { prettyDateTime } from "../helper/date";

const getTagColor = (tag: string) => {
	switch (tag) {
		case "Alpha":
			return "bg-red-100 text-red-500";
		case "Beta":
			return "bg-blue-100 text-blue-500";
		case "Uber alpha":
			return "bg-stone-150 text-stone-500";
		default:
			return "bg-green-100 text-green-400";
	}
};

export default function Navbar() {
	const user = JSON.parse(safeUserStorage() || "{}");

	const navItems = getNavigation(user);

	const handleLogout = () => {
		navigate("/logout");
	};

	return (
		<div>
			<div className="w-48 h-screen box-border hidden invisible md:flex md:visible top-0"></div>
			<div className="w-48 h-screen dark:bg-stone-950 fixed bg-slate-50 border-b-slate-100 dark:border-stone-900 box-border hidden invisible md:flex md:visible top-0">
				<div className="mt-10 flex items-start justify-start flex-col w-full">
					<Link to="/start" className="w-full  mb-10">
						<h1 className="font-bold text-2xl w-full select-none text-center text-blue-950 dark:text-blue-500">
							VASSDUPP
						</h1>
					</Link>
					{navItems.map((cat) => {
						const title = cat[0];
						const catItems: any = cat[1];

						return (
							<div className="flex flex-col ml-6 mb-2" key={"nav-d-cat-" + title}>
								<h2 className="flex w-full font-semibold mt-4 text-sm -mb-1 dark:text-stone-300">
									{title}
								</h2>
								{catItems.map((item: any) => (
									<Link
										key={"nav-" + item[0]}
										to={"/" + item[0]}
										className={(active) =>
											"mt-1.5 hover:opacity-80 text-sm transition-opacity ease-in select-none " +
											(active
												? "text-blue-500 font-semibold"
												: "text-slate-400 opacity-90 font-medium dark:text-stone-500")
										}
									>
										{item[1]}
										{item[2] ? (
											<span
												className={
													"text-xs px-1 py-0.5 rounded font-semibold text-blue-400 bg-blue-100 dark:bg-slate-800 ml-1 " +
													getTagColor(item[2])
												}
											>
												{item[2]}
											</span>
										) : null}
									</Link>
								))}
							</div>
						);
					})}
					<div className="mt-auto flex flex-col w-full items-center relative">
						<div className="mb-5 text-sm font-semibold text-center group select-none cursor-pointer leading-5 pt-10 w-full dark:text-stone-400">
							{user?.firstname}
							<br />
							{user?.lastname}
							<div className="px-4 py-4 rounded hidden bg-slate-200 dark:bg-stone-900 group-hover:block absolute bottom-16 left-5">
								<p className="text-slate-700 font-semibold text-sm flex-nowrap flex dark:text-stone-400">
									Roll:<b className="ml-1">{user?.role}</b>
								</p>
								<p className="text-slate-700 font-semibold text-sm flex-nowrap flex dark:text-stone-400">
									Behörigheter:
									<b className="ml-1">
										{user?.role === "god" ? "All" : user?.permissions}
									</b>
								</p>
								<span className="text-slate-700 font-semibold flex-nowrap flex dark:text-stone-400 text-xs">
									<p>Expires:</p>{" "}
									<b className="ml-1 ">
										{prettyDateTime(user?.expires)
											.substring(0, 16)
											.substring(2)
											.replaceAll("-", "")}
									</b>
								</span>
								<p
									className="hover:opacity-70 text-sm transition-opacity relative ease-in font-semibold select-none mt-4 cursor-pointer px-8 self-center whitespace-nowrap bg-white dark:bg-stone-800 py-2 rounded"
									onClick={() => handleLogout()}
								>
									Logga ut
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="w-full py-5 bg-slate-50 border-b-slate-100 border-r-2 box-border dark:bg-stone-950 flex visible md:hidden md:invisible fixed border-box pr-4">
				<div className="flex items-center justify-start flex-row w-full overflow-x-scroll">
					<Link to="/start">
						<h1 className="font-bold text-2xl select-none text-center bg-slate-50 dark:bg-stone-950 dark:text-stone-100 px-4">
							VASSDUPP
						</h1>
					</Link>
					{navItems.map((cat) => {
						const items: any = cat[1];
						return items.map((item: any) => (
							<Link
								key={"nav-" + item[0]}
								to={item[0]}
								className={(active) =>
									"ml-4 hover:opacity-80 text-sm transition-opacity ease-in font-medium select-none whitespace-nowrap " +
									(active
										? "text-black dark:text-slate-50"
										: "text-slate-400 opacity-90 dark:text-stone-500")
								}
							>
								{item[1]}
							</Link>
						));
					})}
					<p className="ml-5 text-sm font-semibold text-center">
						{user?.firstname} {user?.lastname}
					</p>

					<p
						className="ml-4 hover:opacity-80 text-sm transition-opacity ease-in font-semibold select-none cursor-pointer px-4 whitespace-nowrap box-border bg-slate-200 dark:bg-stone-900 py-1 rounded"
						onClick={() => handleLogout()}
					>
						Logga ut
					</p>
				</div>
			</div>
			<div className="w-full h-20 py-5 bg-slate-50 border-b-slate-100 border-r-2 box-border dark:bg-stone-950 flex visible md:hidden md:invisible border-box pr-4"></div> */}
		</div>
	);
}
