import { useState } from "react";
import { getWSJSON } from "../../helper/xlsx";
import InputFile from "../../components/inputFile";
import View from "../../components/view";

export default function ValidateTrips() {
	const [view, setView] = useState("start");
	const [itTrips, setITTrips] = useState<any[]>([]);
	const [missingTrips, setMissingTrips] = useState<any[]>([]);
	const [selectedDate, setSelectedDate] = useState<null | string>(null);

	const handleTFFile = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = async (event: any) => {
				// Parse data
				const bstr = event.target.result;
				const rows = getWSJSON(bstr);
				const tripsMisssing: any[] = [];

				let count = 0;

				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];

					if (row["Avgångar-Heltutförda"] === 1) {
						continue;
					} else {
						console.log("Trip not completed");
					}

					const date = row["Trafikdygn"].toString().replaceAll("-", "");
					const dateString = `${date.substring(0, 4)}-${date.substring(
						4,
						6
					)}-${date.substring(6, 8)}`;

					if (date !== selectedDate) {
						continue;
					}

					count++;

					const line = row["Linje"].toString().substring(0, 3);
					const trip = row["Tur"].toString();
					const key = `${dateString}-${line}-${trip}`;

					// Check if trip exists in IT-file
					if (!itTrips[key]) {
						tripsMisssing.push(key);

						// Create excel file.
						// const wb = createWBFromRows([])
					} else {
						console.log(itTrips[key][0].line, line);
						console.log(itTrips[key][0].trip, trip);
						console.log(itTrips[key][0].date, dateString);
						console.log("-----");
					}
				}

				console.log(count);
				setMissingTrips(tripsMisssing);
				setView("complete");
			};
			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const handleITFile = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = async (event: any) => {
				// Parse data
				const bstr = event.target.result;
				const rows = bstr.split("\n").map((row: string) => {
					const [line, buss, trip, date, stopId, stopName] = row.split(";");

					return {
						line,
						buss,
						trip,
						date,
						stopId,
						stopName,
					};
				});

				const tripsMap: any = {};

				setSelectedDate(rows[1].date.replaceAll("-", ""));

				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];
					const key = `${row.date}-${row.line.toString().substring(0, 3)}-${row.trip}`;

					if (!tripsMap[key]) {
						tripsMap[key] = [];
					}

					tripsMap[key].push(row);
				}

				setITTrips(tripsMap);
			};
			reader.readAsText(file);
		} catch (error) {
			setView("error");
		}
	};

	return (
		<>
			<h1 className="mt-10 text-2xl font-bold">Trip merger</h1>
			<p className="mb-3 text-sm">
				Följ upp vilka turer som har genomförts och skapa turdata automatiskt
			</p>

			<View show={view === "start"}>
				<p className="mt-6 text-sm font-semibold">IT</p>
				<InputFile name="trips" accept=".csv" onHandleFile={handleITFile} />
				<p className="mt-6 text-sm font-semibold">Trafikförvaltningen</p>
				<InputFile name="trips" accept=".xlsx" onHandleFile={handleTFFile} />
			</View>

			<View show={view === "complete"}>
				<p>Raderna har skapats</p>
				{missingTrips.length > 0 ? (
					<div>
						<p className="text-sm font-semibold text-red-500">
							Följande turer saknas i IT-filen ({missingTrips.length}):
						</p>
						<ul>
							{missingTrips.map((trip, index) => (
								<li key={index}>{trip}</li>
							))}
						</ul>
					</div>
				) : (
					<p className="text-sm font-semibold text-green-500">
						Alla turer finns i IT-filen.
					</p>
				)}
			</View>

			<View show={view === "error"}>
				<p className="text-sm font-semibold text-red-500">Något gick fel, försök igen</p>
			</View>
		</>
	);
}
