import { read, utils, writeFileXLSX } from "xlsx";

export const getWSJSON = (bstr: any, sheetIndex = 0, sheetName?: string): any[] => {
	const wb: any = read(bstr, { type: "binary", cellStyles: true, cellDates: true });

	const index = sheetName ? wb.SheetNames.indexOf(sheetName) : sheetIndex;
	const wsname = wb.SheetNames[index];
	const ws = wb.Sheets[wsname];

	return utils.sheet_to_json(ws).map((row: any) => {
		const newRow: any = {};

		for (let key in row) {
			const newKey = key.replace(/\s/g, "");
			newRow[newKey] = row[key];
		}

		return newRow;
	});
};

export const createXLSXFile = (wb: any, fileName: string) => {
	writeFileXLSX(wb, fileName, {
		cellStyles: true,
	});
};

export const createWBFromRows = (rows: any[], headers: any) => {
	const NEW_WS = utils.aoa_to_sheet([headers, ...rows]);

	/* Create workbook */
	const NEW_WB = utils.book_new();

	/* Add the worksheet to the workbook */
	utils.book_append_sheet(NEW_WB, NEW_WS, "Blad1");

	return NEW_WB;
};

export const createXLSXCopyString = (rows: string[][]) => {
	let copyableTable = "";

	for (let i = 0; i < rows.length; i++) {
		copyableTable += `${rows[i].join("\t")}\n`;
	}

	return copyableTable;
};

export const createXLSXSheet = (wb: any, name: string, headers: any[], rows: any[]) => {
	const NEW_WS = utils.aoa_to_sheet([headers, ...rows]);

	/* Add the worksheet to the workbook */
	utils.book_append_sheet(wb, NEW_WS, name);

	return wb;
};
