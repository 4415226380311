export const VASSDUPP_URL =
	process.env.NODE_ENV === "production"
		? "https://vassdupp.com/api/v1/"
		: "http://localhost:8787/api/v1/";

export const VASSDUPP_VERSION = "1.0.0";

export const XLSX_KEYS_TO_KEEP = [
	"Avtal",
	"AvtalsOmråde",
	"Period",
	"Trafikdygn",
	"DatedVehicleJourneyGid",
	"Tur",
	"Linje",
	"Riktning",
	"StartTid",
	"TLÅ",
	"Omlopp",
	"Fordon",
	"Kommentar",
	"Körd",
	"Enligt",
	"a",
	"b",
	"c",
	"d",
	"Planerad sträcka",
	"Planerad tid",
];

export const XLSX_KEYS_TO_KEEP_SNG = [
	"Trafikdygn",
	"Linje",
	"Tur",
	"StartTid",
	"Omlopp",
	"Fordon",
	"Kommentar",
];

export const XLSX_HEADERS = [
	"Avtal",
	"AvtalsOmråde",
	"Period",
	"Trafikdygn",
	"DatedVehicleJourneyGid",
	"Tur",
	"Linje",
	"Riktning",
	"StartTid",
	"TLÅ",
	"Omlopp",
	"Fordon",
	"Kommentar",
	"Körd",
	"Enligt",
	"Vilken information har inte levererats",
	"Varför har inte informationen levererats",
	"Har turen kört enligt plan? Om inte, vilka hållplatser trafikerades ej?",
	"Varför rapporterades ingen TLÅ?",
	"KM",
	"Tid",
];

export const NEW_XLSX_HEADRS = [
	"Datum",
	"Linje",
	"Tur",
	"Startid",
	"Omlopp",
	"Fordon",
	"SL avvikelse",
	"Fullständighet",
	"Körd",
	"Anledning till avvikelse",
	"Framtida åtgärd",
	"Kommentar",
];

export const REASON_TITLE_MAP = {
	"no-completeness": "Inte påbörjad",
	"part-completeness": "Delvis slutförd",
	"h19": "H19",
};

export const EXCEPTIONS_TITLE_MAP = {
	"booking": "Bokning",
	"part-distance": "Delsträcka",
	"reinforcement": "Förstärkning",
	"external": "Extern",
	"rerouted": "Omlagd körväg",
};

export const STATUS_TITLE_MAP = {
	...REASON_TITLE_MAP,
	"resend-cts": "CTS-turer eftersänd",
	"resend-created": "Skapade turer eftersända",
	"real-h19": "Bekräftad h19",
	"resend-h19": "Eftersänd falsk H19",
	"false-h19": "Falsk h19",
	"cancelled": "Inställd",
	"not-started": "Ej påbörjad",
	"exception": "Undantag",
	"waiting-data": "Väntar på data",
	"rerouted": "Omlagd körväg",
	"resend-failed": "Eftersändning misslyckades",
	"waiting-decision": "Väntar på beslut",
	"wierd-h19": "Konstig H19",
	"close-h19": "Falsk H19 minst 17min sen",
	"bad-resend": "Felaktig eftersändning",
	"looks-like-h19": "Ser ut som H19",
	"looks-not-like-h19": "Ser inte ut som H19",
	"unknown-h19": "Okänd H19",
	"looks-wierd": "Konstig tur",
	"no-vehicle-data": "Saknar GPS-data",
	"late-cancelled": "Inställd i efterhand",
};

export const STATUS_TITLE_MAP_DESC = {
	...REASON_TITLE_MAP,
	"resend-cts": "Turen eftersänd. Hämtad från CTS",
	"resend-created": "Turen eftersänd. Innehåller skapade progresser från GPS",
	"rerouted":
		"Omlagd körväg. Enbart omlagda hållplatser saknar progress. Hastus Geo mitigerar detta.",
	"cancelled": "Turen är inställd och vitesbelagd",
};

const COLOR_MAP = {
	red: "bg-red-200 text-red-800 dark:bg-red-500 dark:text-red-950",
	green: "bg-green-200 text-green-800 dark:bg-green-500 dark:text-green-950",
	yellow: "bg-yellow-200 text-yellow-800 dark:bg-yellow-500 dark:text-yellow-950",
};

export const STATUS_COLOR_MAP = {
	"no-completeness": COLOR_MAP.red,
	"part-completeness": COLOR_MAP.red,
	"h19": COLOR_MAP.red,
	"resend-cts": COLOR_MAP.green,
	"resend-created": COLOR_MAP.green,
	"resend-failed": COLOR_MAP.red,
	"real-h19": COLOR_MAP.green,
	"cancelled": COLOR_MAP.yellow,
	"resend-h19": COLOR_MAP.green,
	"not-started": COLOR_MAP.red,
	"exception": COLOR_MAP.green,
	"waiting-data": COLOR_MAP.yellow,
	"rerouted": COLOR_MAP.green,
	"waiting-decision": COLOR_MAP.yellow,
	"wierd-h19": COLOR_MAP.yellow,
	"false-h19": COLOR_MAP.green,
	"close-h19": COLOR_MAP.green,
	"bad-resend": COLOR_MAP.red,
	"looks-like-h19": COLOR_MAP.red,
	"looks-not-like-h19": COLOR_MAP.red,
	"unknown-h19": COLOR_MAP.red,
	"no-vehicle-data": COLOR_MAP.yellow,
	"looks-wierd": COLOR_MAP.yellow,
	"late-cancelled": COLOR_MAP.yellow,
};

export const DEPOT_FULL: any = {
	taga: "TÄGA",
	noga: "NOGA",
	maga: "MÄGA",
	gaga: "Gävle",
};

export const SELECT_ITEMS: any = {
	"taga": "Norrort",
	"-0": "0-",
	"noga": "Norrtälje",
	"-1": "0-",
	"maga": "Märsta",
	/* "maga": "MÄGA", */
	"-2": "0-",
	"gaga": "Gästrikland",
	/* "gaga": "Gävle", */
};
