import Popup from "../../components/popup";
import TableHeader from "../../components/tableHeader";
import useFetch from "../../helper/swr";
import { useState } from "react";

const HEADER_NAMES = [
	{
		name: "Linjer",
		width: "w-36",
	},
	{
		name: "Startdatum",
		width: "w-36",
	},
	{
		name: "Slutdatum",
		width: "w-36",
	},
	{
		name: "Hållplatser",
		width: "w-36",
	},
	{
		name: "Status",
		width: "ml-auto w-36",
	},
];

type TripType = {
	lines: string;
	startDate: string;
	endDate: string;
	stops: string;
	status: string;
};

function TripRow({ trip: { lines, startDate, endDate, stops, status } }: { trip: TripType }) {
	const currentDate = new Date().getTime();
	const start = new Date(startDate).getTime();
	const end = new Date(endDate).getTime();

	// Check if the current date is between the start and end date
	const isActive = currentDate >= start && currentDate <= end;

	return (
		<div className="flex flex-row w-full items-center px-4 py-2 border-b border-slate-200 dark:border-stone-900 hover:bg-stone-50 ease-out transition-all">
			<p className="font-semibold text-sm w-36">{lines}</p>
			<p className="font-semibold text-sm w-36 ">{startDate}</p>
			<p className="font-semibold text-sm w-36">{endDate}</p>
			<p className="font-semibold text-sm">{stops}</p>
			<div className="w-36 ml-auto flex">
				<p className={"font-semibold text-xs px-2 rounded py-0.5 " + (isActive ? "bg-green-200 text-green-700" : "bg-gray-200 text-gray-700")}>
					{isActive ? "Aktiv" : "Inaktiv"}
				</p>
			</div>
		</div>
	);
}

const Stops = ({
	stops,
	isLoading,
	isError,
}: {
	stops: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (stops === undefined || stops?.length === 0) {
		return <p className="m-auto text-stone-500">Inga hållplatser hittades</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	return stops.map((trip, i) => <TripRow key={"dr-" + i} trip={trip} />);
};

export default function TrafficChanges() {
	const [show, setShow] = useState(false);
	
	const {
		data: reroutedStops,
		isLoading: reroutedStopsIsLoading,
		isError: reroutedStopsIisError,
	} = useFetch("/trafficchanges");

	const createRerouted = () => {
		setShow(true);
	};

	return (
		<>
			<Popup show={show} setShow={setShow}>
				<div className="flex flex-col p-6">
					<h1 className="font-semibold text-2xl">Ändra hållplatser</h1>
					<p className="font-medium text-stone text-sm w-40 text-stone-500 whitespace-nowrap">
						Alla skickade eftersändningar
					</p>
					<div className="flex flex-col w-full mt-6">
						<div className="flex flex-row items-center justify-between mt-4">
							<div className="flex flex-col w-1/2">
								<label className="font-medium text-stone-500 text-sm">
									Startdatum
								</label>
								<input
									type="text"
									placeholder="Startdatum"
									className="border border-stone-200 rounded px-2 py-1 mt-1"
								/>
							</div>
							<div className="flex flex-col w-1/2 ml-4">
								<label className="font-medium text-stone-500 text-sm">
									Slutdatum
								</label>
								<input
									type="text"
									placeholder="Slutdatum"
									className="border border-stone-200 rounded px-2 py-1 mt-1"
								/>
							</div>
						</div>

						<div className="flex flex-row items-center justify-between mt-4">
							<div className="flex flex-col w-1/2">
								<label className="font-medium text-stone-500 text-sm">
									Hållplatser
								</label>
								<input
									type="text"
									placeholder="Hållplatser"
									className="border border-stone-200 rounded px-2 py-1 mt-1"
								/>
							</div>
						</div>

						<div className="flex flex-row items-center justify-end mt-8">
							<div
								onClick={() => setShow(false)}
								className="flex ml-2 px-6 py-2 bg-blue-100 text-blue-600 font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
							>
								Avbryt
							</div>
							<div
								onClick={() => createRerouted()}
								className="flex ml-2 px-6 py-2 bg-blue-600 text-white font-medium rounded text-sm cursor-pointer hover:bg-blue-700 transition-colors ease-linear"
							>
								Spara
							</div>
						</div>
					</div>
				</div>
			</Popup>
			<div className="flex flex-1 px-6 flex-col">
				<div className="flex flex-row items-center justify-between mb-8 mt-12">
					<div>
						<h1 className="font-semibold text-2xl">Ändrade hållplatser</h1>
						<p className="font-medium text-stone text-sm w-40 text-stone-500 whitespace-nowrap">
							Alla skickade eftersändningar
						</p>
					</div>
					<div
						onClick={() => setShow(true)}
						className="flex ml-2 px-6 py-2 bg-blue-100 text-blue-600 font-medium rounded text-sm cursor-pointer hover:bg-blue-50 transition-colors ease-linear"
					>
						Ändra hållplats
					</div>
				</div>

				<div className="flex flex-1 flex-col w-full">
					<TableHeader items={HEADER_NAMES} />
					<Stops
						stops={reroutedStops}
						isLoading={reroutedStopsIsLoading}
						isError={reroutedStopsIisError}
					/>
				</div>
			</div>
		</>
	);
}
