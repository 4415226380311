import { Link } from "wouter";
import ContractSelector from "../../components/contractSelector";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import { getYearMonthFormat } from "../../helper/date";
import useFetch from "../../helper/swr";
import { useMemo, useState } from "react";
import { StatBox } from "../../components/statbox";
import usePersistentState from "../../helper/usePersistentState";
import { sortRows } from "../../helper/sorter";

const HEADER_NAMES = [
	{ key: "operatingDay", name: "Datum", width: "w-24" },
	{ key: "line", name: "Linje", width: "w-20" },
	{ key: "trip", name: "Tur", width: "w-24" },
	{ key: "stops", name: "Hållplatser", width: "w-32" },
	{ key: "vehicle", name: "Fordon", width: "w-28" },
	{ key: "comment", name: "Kommentar", width: "flex-1" },
	{ key: "status", name: "Status", width: "w-40" },
];

export const TripStatus = ({ status }: { status: any }) => {
	return (
		<div className="flex flex-row w-40">
			<p
				className={
					"font-medium whitespace-nowrap px-2 py-0.5 text-xs rounded " +
					STATUS_COLOR_MAP[status]
				}
			>
				{STATUS_TITLE_MAP[status]}
			</p>
		</div>
	);
};

export function TripRow({
	trip,
}: {
	trip: {
		operatingDay: string;
		line: string;
		trip: string;
		deviatedStops: string[];
		status: any;
		vehicle: string;
		calls: string[];
		type: string;
		comment: string;
	};
}) {
	return (
		<Link
			to={`/resend/trips/${trip.operatingDay}/${trip.line}/${trip.trip}/h19`}
			className="flex flex-row w-full items-center px-4 py-2 hover:bg-stone-50 dark:hover:bg-stone-950 border-b border-slate-200 dark:border-stone-900 ease-out transition-all"
		>
			<p className="font-semibold text-sm w-24">
				{trip.operatingDay.substring(0, 4)}-{trip.operatingDay.substring(4, 6)}-
				{trip.operatingDay.substring(6, 8)}
			</p>
			<div className="flex w-20">
				<p
					className={
						"font-semibold text-sm px-1.5 rounded bg-slate-200 dark:bg-blue-950 dark:text-blue-400"
					}
				>
					{trip.line}
				</p>
			</div>
			<p className="font-semibold text-sm w-24">{trip.trip}</p>
			<p className="font-semibold text-sm w-32">
				{trip.deviatedStops.length}/{trip.calls.length}st{" "}
				<span className="text-gray-500 text-xs">
					({((trip.deviatedStops.length / trip.calls.length) * 100).toFixed(2)}%)
				</span>
			</p>
			<p className="w-28">{trip.vehicle ?? ""}</p>
			<p className="flex-1">{trip.comment}</p>
			<TripStatus status={trip.status} />
		</Link>
	);
}

const FILTER_MAP = {
	/* 	"cancelled": true, */
	/* 	"real-h19": true, */
	/* 	"false-h19": true, */
	/* 	"exception": true, */
	/* 	"close-h19": true, */
	/* 	"bad-resend": true, */
};

const Trips = ({
	trips,
	isLoading,
	isError,
	sortBy,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
	sortBy: string;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	const filteredTrips = sortRows(
		trips.filter((trip: any) => FILTER_MAP[trip.status] === undefined),
		sortBy
	);

	return (
		<div className="flex flex-1 w-full flex-col">
			{filteredTrips.map((trip, i) => (
				<TripRow key={"dr-" + i} trip={trip} />
			))}
		</div>
	);
};

const calculateStatuses = (trips: any) => {
	const TYPES_OF_STATUS_VALUES = {};
	const TYPES_OF_STATUS_KEYS = [];

	for (let i = 0; i < trips.length; i++) {
		const trip = trips[i];
		if (!TYPES_OF_STATUS_VALUES[trip.status]) {
			TYPES_OF_STATUS_VALUES[trip.status] = 1;
			TYPES_OF_STATUS_KEYS.push(trip.status);
		} else {
			TYPES_OF_STATUS_VALUES[trip.status]++;
		}
	}

	return [TYPES_OF_STATUS_KEYS, TYPES_OF_STATUS_VALUES];
};

const TripsHeader = ({
	trips,
	isLoading,
	isError,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	const heavyResult: any = useMemo(() => {
		if (isLoading || isError || trips === undefined || trips?.length === 0) {
			return [];
		}

		return calculateStatuses(trips);
	}, [trips, isLoading, isError]);

	if (heavyResult.length === 0) {
		return <p></p>;
	}

	return (
		<div className="flex w-full flex-row mb-6">
			{heavyResult[0].map((status, i) => (
				<StatBox
					key={"stat-" + i}
					title={STATUS_TITLE_MAP[status]}
					procentage={((heavyResult[1][status] / trips.length) * 100).toFixed(2)}
					value={heavyResult[1][status]}
				/>
			))}
		</div>
	);
};

export default function H19Trips() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const [sortBy, setSortBy] = usePersistentState("vassdupp-sort-h19t", "status-asc");

	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
		mutate,
	} = useFetch(`h19s?contract=${contract}&month=${month}&page=${0}`);

	return (
		<>
			<div className="flex flex-1 px-6 flex-col">
				<div className="flex w-full justify-between items-center">
					<div>
						<h1 className="mt-12 font-semibold text-2xl">H19 turer</h1>
						{trips ? (
							<h1 className="font-semibold text-sm text-gray-700">
								{trips.length}st turer under period
							</h1>
						) : null}
						<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
							Alla turer som är h19 i trafiken för en specifierad period
						</p>
					</div>
					<div className="flex flex-col">
						<div className="flex">
							<MonthSelector value={month} onChange={setMonth} />
							<ContractSelector value={contract} onChange={setContract} />
						</div>
					</div>
				</div>

				<TripsHeader trips={trips} isLoading={isLoadingTrips} isError={isTripError} />

				<div className="flex flex-1 flex-col w-full">
					<TableHeader items={HEADER_NAMES} sortBy={sortBy} setSortBy={setSortBy} />
					<Trips
						trips={trips}
						isLoading={isLoadingTrips}
						isError={isTripError}
						sortBy={sortBy}
					/>
				</div>
			</div>
		</>
	);
}
