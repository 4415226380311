import { Link, useParams } from "wouter";
import { MapContainer, Polyline, TileLayer, useMap } from "react-leaflet";
import toast from "react-hot-toast";

import postman from "../../helper/postman";
import { useState } from "react";
import { STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import useFetch from "../../helper/swr";
import Input from "../../components/input";
import InputSelect from "../../components/inputSelect";
import DynamicCircle from "../../components/dynamicCircle";
import { prettyTime } from "../../helper/date";

const TYPES = {
	md: "Saknar turdata",
	h19: "H19",
};

const TYPE_URL_MAP = {
	md: "deviatedtrips",
	h19: "h19s",
};

/* function getTimeUntilNextFetch(): number {
	const now = new Date();
	const startHour = 8; // Fetching starts at 08:00
	const endHour = 15; // Fetching ends at 15:00
	const fetchIntervalMinutes = 15;

	const currentHour = now.getHours();
	const currentMinute = now.getMinutes();

	// If current time is before the fetching window, calculate minutes until 08:00
	if (currentHour < startHour) {
		const openingTime = new Date(now);
		openingTime.setHours(startHour, 0, 0, 0); // Set to 08:00

		return Math.ceil((openingTime.getTime() - now.getTime()) / (1000 * 60));
	}

	// If current time is after the fetching window, calculate minutes until the next day 08:00
	if (currentHour >= endHour) {
		const nextDayOpeningTime = new Date(now);
		nextDayOpeningTime.setDate(now.getDate() + 1);
		nextDayOpeningTime.setHours(startHour, 0, 0, 0); // Set to 08:00 the next day

		return Math.ceil((nextDayOpeningTime.getTime() - now.getTime()) / (1000 * 60));
	}

	// Calculate the time remaining until the next fetch interval within the window
	const minutesPastInterval = currentMinute % fetchIntervalMinutes;
	const minutesUntilNextFetch = fetchIntervalMinutes - minutesPastInterval;

	return minutesUntilNextFetch;
}

const caluclateHasProgress = (marker: any) => {
	return (
		(marker.plannedArrivalTime !== undefined && marker.actualArrivalTime) ||
		(marker.plannedDepartureTime !== undefined && marker.actualDepartureTime !== undefined)
	);
};

// Helper function to format time in hours or minutes
function formatTime(minutes: number): string {
	if (minutes >= 60) {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return `${hours}h${remainingMinutes > 0 ? ` and ${remainingMinutes}min` : ""}`;
	} else {
		return `${minutes} minute(s)`;
	}
} */

const caluclateH19Progress = (item: any, amount = 19) => {
	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / (1000 * 60);

	return timeDifference > amount;
};

const getProgressColor = (item: any) => {
	console.log(item);
	if (caluclateH19Progress(item)) {
		return "bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200";
	}

	if (caluclateH19Progress(item, 17)) {
		return "bg-yellow-200 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200";
	}

	return "bg-green-200 text-green-800 dark:bg-green-900 dark:text-green-200";
};

const getProgressColorCircle = (item: any) => {
	if (!item.actualArrivalTime && !item.actualDepartureTime) {
		return "gray";
	}

	if (caluclateH19Progress(item)) {
		return "red";
	}

	if (caluclateH19Progress(item, 17)) {
		return "orange";
	}

	return "green";
};

const getPrettyTime = (secounds: number) => {
	return Math.abs(secounds) < 60 ? `${Math.round(secounds)}s` : `${Math.floor(secounds / 60)}m`;
};

const getTimeDifference = (item: any) => {
	const plannedDate = new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime);
	const actualDate = new Date(item?.actualDepartureTime ?? item.actualArrivalTime);

	const timeDifference = (actualDate.getTime() - plannedDate.getTime()) / 1000;

	return getPrettyTime(timeDifference);
};

const createDeviatedStopsWithTime = (stops) => {
	const deviatedStopsTime = {};
	const tempStops: any = Object.entries(stops);

	for (let i = 0; i < tempStops.length; i++) {
		const [_, stop] = tempStops[i];
		deviatedStopsTime[stop.plannedTime.substring(0, 5)] = stop;
	}

	return deviatedStopsTime;
};

const checkIfTFDeviated = (item: any, deviatedStops) => {
	const deviatedStopsTime = createDeviatedStopsWithTime(deviatedStops);

	if (deviatedStops[item.stopName]) {
		return (
			<p className="text-[10px] bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200 text px-1 py-0.5 rounded">
				{deviatedStops[item.stopName].actualTime.substring(0, 5)}
			</p>
		);
	}

	const time = (item?.plannedArrivalTime ?? item.plannedDepartureTime)
		.split("T")[1]
		.substring(0, 5);
	const stop = deviatedStopsTime[time];

	if (stop) {
		return (
			<p className="text-[10px] bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200 px-1 py-0.5 rounded">
				{stop.actualTime.substring(0, 5)}
			</p>
		);
	}

	return null;
};

// Filter out unique calls based on externalId.
const getUniqueCalls = (calls): any => {
	return calls;
	const uniqueCalls: any = calls.reduce((acc, call) => {
		// If the call doesn't exist in the accumulator, add it.
		if (!acc[call.externalId]) {
			acc[call.externalId] = call;
		} else {
			// Add actual another actual time if it exists.
			if (call.actualArrivalTime) {
				acc[call.externalId].actualArrivalTime = [
					acc[call.externalId].actualArrivalTime,
					call.actualArrivalTime,
				];
			}

			if (call.actualDepartureTime) {
				acc[call.externalId].actualDepartureTime = [
					acc[call.externalId].actualDepartureTime,
					call.actualDepartureTime,
				];
			}
		}

		return acc;
	}, {});

	const res = Object.values(uniqueCalls).sort(
		(a: any, b: any) => a.sequenceInJourney - b.sequenceInJourney
	);

	return res;
};

const caluclateStopTime = (item: any, deviatedStops) => {
	return (
		<>
			{checkIfTFDeviated(item, deviatedStops)}
			<p className={"text-right text-[10px] px-4 py-1 rounded"}>
				{prettyTime(
					new Date(item?.plannedDepartureTime ?? item.plannedArrivalTime).getTime()
				)}
			</p>
			{item?.actualDepartureTime || item.actualArrivalTime ? (
				<p
					className={
						"text-center px-2 text-[10px] py-0.5 min-w-10 tex rounded " +
						getProgressColor(item)
					}
				>
					{getTimeDifference(item)}
				</p>
			) : (
				<p className="text-center text-[10px] w-10 py-0.5 rounded bg-red-200 text-red-800 dark:bg-red-900 dark:text-red-200">
					-
				</p>
			)}
		</>
	);
};

const FitBundsMap = ({ tripData }: { tripData: any }) => {
	const map = useMap();

	if (tripData?.calls.length > 1) {
		map.fitBounds(
			[
				[tripData.calls[0].stopLat, tripData.calls[0].stopLon],
				[
					tripData.calls[Math.floor(tripData.calls.length / 2)].stopLat,
					tripData.calls[Math.floor(tripData.calls.length / 2)].stopLon,
				],
				[
					tripData.calls[tripData.calls.length - 1].stopLat,
					tripData.calls[tripData.calls.length - 1].stopLon,
				],
			],
			{
				padding: [5, 5],
			}
		);
	}

	return null;
};

const TimeGraph = ({ tripData }: { tripData: any }) => {
	if (!tripData || tripData.calls.length === 0) {
		return null;
	}

	const data = tripData.calls.map((call: any) => {
		const plannedTime = new Date(
			call.plannedDepartureTime ?? call.plannedArrivalTime
		).getTime();
		const actualTime = new Date(call.actualDepartureTime ?? call.actualArrivalTime).getTime();
		const onTimeDifference = (actualTime - plannedTime) / (1000 * 60); // Difference in minutes
		return {
			stop: call.stopName,
			onTimeDifference,
		};
	});

	const filteredData = data.map((d) => d.onTimeDifference);

	const maxDifferenceY = Math.max(...filteredData);
	const minDifferenceY = Math.min(...filteredData);

	return (
		<svg viewBox="0 0 500 300" preserveAspectRatio="xMidYMid meet" className="w-full h-auto">
			<g transform="translate(50, 20)">
				{/* Y Axis */}
				<line
					x1="0"
					y1="-12"
					x2="0"
					y2="250"
					className="stroke-black dark:stroke-white"
					strokeWidth="2"
				/>
				<line
					x1="0"
					y1="250"
					x2="400"
					y2="250"
					className="stroke-black dark:stroke-white"
					strokeWidth="2"
				/>

				<line
					x1="0"
					y1={250 - (19 * 250) / maxDifferenceY}
					x2="400"
					y2={250 - (19 * 250) / maxDifferenceY}
					className="stroke-red-500 dark:stroke-red-500"
					strokeWidth="2"
					strokeDasharray={5}
				/>

				{/* Y Axis Labels */}
				<text x="-10" y="0" textAnchor="end" className="fill-black dark:fill-white">
					{getPrettyTime(maxDifferenceY * 60)}
				</text>
				<text x="-10" y="250" textAnchor="end" className="fill-black dark:fill-white">
					{getPrettyTime(minDifferenceY * 60)}
				</text>

				{/* Line */}
				{data.map((d: { stop: string; onTimeDifference: number }, i: number) => {
					const nextPoint = data[i + 1];
					if (!nextPoint) return null;

					const x1 = (i * 400) / data.length;
					const y1 = 250 - (d.onTimeDifference * 250) / maxDifferenceY;
					const x2 = ((i + 1) * 400) / data.length;
					const y2 = 250 - (nextPoint.onTimeDifference * 250) / maxDifferenceY;
					const timeDiff = Math.max(d.onTimeDifference, nextPoint.onTimeDifference);

					let color = "green";

					if (timeDiff < 19 && timeDiff > 17) {
						color = "yellow";
					} else if (timeDiff > 19) {
						color = "red";
					}

					return (
						<line
							key={i}
							x1={x1}
							y1={y1}
							x2={x2}
							y2={y2}
							stroke={color}
							strokeWidth="2"
						/>
					);
				})}
			</g>
		</svg>
	);
};

export default function ViewTrip() {
	interface Params {
		operatingDay: string;
		line: string;
		trip: string;
		date: string;
		type: keyof typeof TYPES;
	}

	const params = useParams<Params>();
	/* 	const [_, setLocation] = useLocation(); */
	const [typingTimeout, setTypingTimeout] = useState(null);

	const handleChangeTrip = ({ comment, status }: { comment: string; status: string }) => {
		mutate((data: any) => {
			return {
				...data,
				comment,
				status,
			};
		}, false);

		postman
			.put(`h19s`, {
				operatingDay: params.operatingDay,
				line: params.line,
				trip: params.trip,
				status,
				comment,
			})
			.then(({ data }: any) => {
				toast.success("Tur sparad");

				/* const nextTrip = data.nextTrip;

					if (!nextTrip || nextTrip?.trip === undefined) {
						toast("Inga fler turer att visa");
						return;
					}

					// Go to next trip.
					setLocation(
						`/trips/${nextTrip.operatingDay}/${nextTrip.line}/${nextTrip.trip}/h19`
					); */
			})
			.catch((error) => {
				toast.error("Något gick fel");
				console.error(error);
			});
	};

	const handleChangeComment = (comment) => {
		mutate((data: any) => {
			return {
				...data,
				comment,
			};
		}, false);

		// Clear the previous timeout
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}

		// Set a new timeout to save the value after 2 seconds
		setTypingTimeout(
			setTimeout(() => {
				handleChangeTrip({ comment, status: tripData.status });
			}, 750)
		);
	};

	const handleDeviation = (status: string) => {
		handleChangeTrip({ comment: tripData.comment, status });
	};

	const {
		data,
		isLoading: isLoadingTripData,
		mutate,
	} = useFetch(`h19s?date=${params.operatingDay}&line=${params.line}&trip=${params.trip}`);

	if (isLoadingTripData) {
		return <p>Laddar...</p>;
	}

	if (!data) {
		return <p>Något gick fel</p>;
	}

	const tripData = { ...data, calls: getUniqueCalls(data.calls) };

	const lightTile = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
	const darkTile = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";

	const darkMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? true : false;

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex flex-row mb-8 justify-between items-center">
				<div>
					<h1 className="font-semibold text-2xl mt-12">
						{tripData.line}/{tripData.trip}
					</h1>
					<p className="text-stone-500 font-semibold -my-1">
						{tripData.operatingDay.substring(0, 4)}-
						{tripData.operatingDay.substring(4, 6)}-
						{tripData.operatingDay.substring(6, 8)}
					</p>
					<p className="text-stone-500 font-semibold mb-2">{tripData.vehicle}</p>
					<p
						className={
							"rounded font-semibold text-sm py-0.5 self-auto text-center px-2 " +
							STATUS_COLOR_MAP[tripData.status]
						}
					>
						{STATUS_TITLE_MAP[tripData.status]}
					</p>
				</div>
				<Link
					to="/resend/h19trips"
					className="text-stone-900 text-sm font-semibold cursor-pointer bg-stone-100 dark:bg-stone-900 dark:text-white px-4 py-2 rounded"
				>
					Tillbaka
				</Link>
			</div>
			<div className="flex flex-row w-full">
				<div className="flex w-1/4 flex-col min-w-80">
					<div className="flex w-full">
						<TimeGraph tripData={tripData} />
					</div>
					<div className="flex flex-col w-full pr-4 overflow-y-scroll">
						<div className="flex flex-row justify-between px-2 py-[0.5px]">
							<p className="text-[10px] font-semibold">Hållplats</p>
							<div className="flex flex-row">
								<p className="text-[10px] mr-[20px] font-semibold">TF</p>
								<p className="text-[10px] mr-3 font-semibold">Planerad</p>
								<p className="text-[10px] mr-0.5 font-semibold">Verklig</p>
							</div>
						</div>
						{tripData?.calls
							? tripData?.calls.map((item) => (
									<div className="flex flex-row justify-between px-2 py-[0.5px]">
										<p className="text-[10px]">{item.stopName}</p>
										<div className="flex flex-row items-center">
											{caluclateStopTime(item, tripData.deviatedStops)}
										</div>
									</div>
							  ))
							: null}
					</div>
				</div>
				{/* {tripData.Status === "waiting-data" ? (
					<div className="flex flex-col w-3/4">
						<p className="text-stone-500 font-semibold">Väntar på data</p>
						<p className="text-stone-500 font-semibold">
							Hämtning sker om: {formatTime(getTimeUntilNextFetch())}
						</p>
					</div>
				) : null} */}
				<div className="flex flex-col w-3/4 max-h-[calc(100vh-240px)] h-screen">
					<MapContainer
						center={
							tripData?.calls.length > 1
								? [
										tripData.calls[Math.floor(tripData?.calls.length / 2)]
											?.stopLat,
										tripData.calls[Math.floor(tripData.calls?.length / 2)]
											?.stopLon,
								  ]
								: [59.43926662897951, 18.084435863103256]
						}
						zoom={11}
					>
						<TileLayer url={darkMode ? darkTile : lightTile} />
						{tripData.calls.map((marker, i) => (
							<>
								{
									// Add a line between the markers, except for the last one
									i < tripData.calls.length - 1 && (
										<Polyline
											positions={[
												[marker.stopLat, marker.stopLon],
												[
													tripData.calls[i + 1].stopLat,
													tripData.calls[i + 1].stopLon,
												],
											]}
											color="black"
											opacity={
												!marker.actualArrivalTime &&
												!marker.actualDepartureTime
													? 0.4
													: 1
											}
										/>
									)
								}
								<DynamicCircle
									center={[marker.stopLat, marker.stopLon]}
									radius={30}
									fillOpacity={1}
									fillColor={getProgressColorCircle(marker)}
									color={getProgressColorCircle(marker)}
								/>
							</>
						))}
						<FitBundsMap tripData={tripData} />
					</MapContainer>
					<div className="flex mt-5 justify-start">
						<div className="flex mt-5">
							<div className="w-60 mr-2">
								<InputSelect
									placeholder="Status"
									value={tripData.status}
									onChange={handleDeviation}
									items={[
										["real-h19", "Turen är h19"],
										["cancelled", "Inställd tur"],
										["false-h19", "Turen är utan avvikelse"],
										["exception", "Undantag"],
										["-", "", true],
										["close-h19", "Nära H19"],
										["wierd-h19", "Konstig tur"],
										["looks-like-h19", "Ser ut som H19"],
										["looks-not-like-h19", "Ser inte ut som H19"],
										["unknown-h19", "Vet ej"],
										["waiting-decision", "Väntar på beslut"],
										["waiting-data", "Väntar på data"],
									]}
								/>
							</div>
							<Input
								placeholder="Kommentar"
								value={tripData.comment}
								onChange={handleChangeComment}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
