import { useEffect, useState } from "react";
import { fetchUser, fetchUsers } from "../helper/network";

import Input from "../components/input";
import postman from "../helper/postman";
import InputSelect from "../components/inputSelect";
import { useParams } from "wouter";
import { safeUserStorage } from "../helper/storage";
import UserPermissionSelector from "../components/userPermissionSelector";
import { navigate } from "wouter/use-browser-location";

const userRoles: any = [
	["admin", "Admin"],
	["user", "User"],
];

const permissionsMap: any = {
	apc: "Anrop helper",
	bp: "Parkering",
};

export default function User({}) {
	const { username } = useParams<{ username: string }>();

	const { data, mutate: mutateUser } = fetchUser(username);
	const { mutate: mutateUsers } = fetchUsers();
	const user = JSON.parse(safeUserStorage() || "{}");

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [role, setRole] = useState("");
	const [usernameValue, setUsernameValue] = useState("");
	const [password, setPassword] = useState("");
	const [permissions, setPermissions] = useState("");

	useEffect(() => {
		if (data) {
			setFirstname(data.Firstname);
			setLastname(data.Lastname);
			setRole(data.Role);
			setUsernameValue(data.Username);

			const permissions = data?.Permissions?.split(",")

				.map((permission: string) => permissionsMap[permission])
				.join(", ");

			setPermissions(
				permissions !== "" && permissions
					? permissions
					: data.Role !== "user"
					? "Alla rättigheter"
					: "Inga rättigheter"
			);
		}
	}, [data]);

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!firstname || !lastname || !username || !role) {
			alert("Fyll i alla fält");
			return;
		}

		console.log("Creating user");

		try {
			await postman.put("/users/" + username, {
				firstname,
				lastname,
				role,
				username,
				password,
			});

			mutateUsers(
				(data: any) => [
					...data,
					{
						Firstname: firstname,
						Lastname: lastname,
						Role: role,
						Username: username,
						Password: "",
						Permissions: [],
					},
				],
				false
			);

			mutateUser((data: any) => ({
				...data,
				Firstname: firstname,
				Lastname: lastname,
				Role: role,
				Username: username,
				Password: "",
				Permissions: [],
			}));
		} catch (error: any) {
			// Check if network error or badrequest.
			if (error?.response?.status === 400) {
				alert(
					"Något gick fel. Kontrollera att alla fält är korrekt ifyllda och försök igen."
				);
			} else {
				alert("Något gick fel");
			}
		}
	};

	const handleDeleteUser = async () => {
		if (confirm("Är du säker på att du vill radera användaren?")) {
			try {
				await postman.delete(`/users/${data.Username}`);

				mutateUsers((data: any) => data.filter((user: any) => user.Username !== username));
				navigate("/users");
			} catch (error: any) {
				alert("Något gick fel");
			}
		}
	};

	return data ? (
		<div className="flex flex-1 flex-col">
			<form
				className="flex flex-col justify-betweem w-full h-full items-center py-12 px-20"
				onSubmit={handleSubmit}
			>
				<div className="flex flex-col mb-6 self-start">
					<h1 className="font-medium text-xl">
						{firstname} {lastname}
					</h1>
					<p className="text-slate-500 text-sm font-semibold dark:text-stone-500">
						{username}
					</p>
					<p className="text-slate-500 text-sm dark:text-stone-500">{role}</p>
				</div>
				<div className="mt-6 w-full">
					<Input placeholder="Förnamn" value={firstname} onChange={setFirstname} />
				</div>
				<div className="mt-6 w-full">
					<Input placeholder="Efternamn" value={lastname} onChange={setLastname} />
				</div>
				<div className="mt-6 w-full">
					<InputSelect
						items={userRoles}
						onChange={setRole}
						placeholder="Roll"
						value={role}
					/>
				</div>
				<div className="mt-6 w-full">
					<Input
						placeholder="Användarnamn"
						value={usernameValue}
						onChange={setUsernameValue}
					/>
				</div>
				<div className="mt-6 w-full">
					<Input
						placeholder="Lösenord"
						type="password"
						value={"password"}
						disabled
						onChange={setPassword}
					/>
				</div>
				<div className="mt-6 w-full">
					<UserPermissionSelector
						onChange={setPermissions}
						placeholder="Rättigheter"
						value={permissions}
					/>
				</div>

				<div className="flex flex-row ] mt-6 justify-center items-center">
					{user.username !== data.Username ? (
						<div
							className="bg-red-300 dark:text-black px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80"
							onClick={() => {
								handleDeleteUser();
							}}
						>
							Radera användare
						</div>
					) : null}
					<input
						type="submit"
						className="bg-blue-200 px-4 py-2 rounded font-medium cursor-pointer hover:opacity-80 ml-6 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
						value="Spara ändringar"
					/>
				</div>
			</form>
		</div>
	) : (
		<p></p>
	);
}
