import useSWR from "swr";
import VEHICLE_INFO from "../data/vehicles/vehicle_info";
import { getVehicleAssignment, getVehicleAssignmentColor } from "../helper/vehicles";
import fetcherClean from "../helper/fetcherClean";

const selfDeopMap: any = {
	taga: "TÄGA",
	noga: "NOGA",
	maga: "MÄGA",
	gaga: "GÄGA",
};

export const getFuelLevelColor = (fuel: number) => {
	if (fuel > 40) {
		return "text-stone-500";
	} else if (fuel > 20) {
		return "text-amber-500 opacity-70";
	} else {
		return "text-red-600";
	}
};

export const getFuelLevelColorCSS = (fuel: number) => {
	if (fuel > 40) {
		return "fill-stone-500";
	} else if (fuel > 20) {
		return "fill-amber-500";
	} else {
		return "fill-red-600";
	}
};

export const getTempLevelColor = (temp: number) => {
	if (temp < 26 && temp > 14) {
		return "text-stone-500";
	} else if (temp > 5) {
		return "text-amber-500 opacity-70";
	} else {
		return "text-red-600";
	}
};

export const DisplayBusRamps = ({
	vehicles,
	depot,
	extraData,
}: {
	vehicles: any;
	depot: any;
	extraData: boolean;
}) => {
	const { data: journeysData } = useSWR(
		"https://storage.vassdupp.com/lines-combined.json",
		fetcherClean,
		{
			errorRetryCount: 1,
			errorRetryInterval: 4000,
			dedupingInterval: 100000,
			revalidateIfStale: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			shouldRetryOnError: false,
		}
	);

	if (!vehicles) {
		return <p>Laddar...</p>;
	}

	return VEHICLE_INFO[depot].map((col: any, iCol: any) => (
		<div
			key={"column-" + iCol}
			className="flex flex-col mt-2 last:border-r border-black min-w-[245px]"
			style={{ width: `${Math.round(100 / VEHICLE_INFO[depot].length)}%` }}
		>
			{col.map((cat: any, iCat: any) => (
				<div
					className={
						"pm-2 border-l border-b border-black dark:border-stone-900 flex-1" +
						(cat.height === "not-full" ? "flex-1" : "")
					}
					key={cat.type + "-" + iCat}
				>
					<h2
						className={
							"py-1 px-2 font-extrabold " +
							(cat.color === "red"
								? "bg-red-300 dark:bg-red-950/60 dark:text-red-400"
								: "bg-blue-300 dark:bg-blue-950/60 dark:text-blue-400")
						}
					>
						{cat.type}
					</h2>
					<div className="flex flex-col">
						{cat.vehicles.map((vehicle: any) => (
							<div
								key={cat.type + "-" + vehicle}
								className="flex text-sm flex-row border-b items-center justify-between px-3 border-black dark:border-stone-900 box-border"
							>
								<div className="flex">
									<p
										className={
											"text-2xl min-w-[62px]" +
											((vehicles[vehicle]?.ramp.length > 3 &&
												vehicles[vehicle]?.ramp !== selfDeopMap[depot]) ||
											vehicles[vehicle]?.ramp === "-"
												? " opacity-40 font-medium"
												: " font-bold")
										}
									>
										{vehicle}
									</p>
									{vehicles[vehicle]?.fuel ? (
										<p
											className={
												"flex text-xl font-semibold ml-0.5 min-w-[50px] " +
												getFuelLevelColor(vehicles[vehicle]?.fuel)
											}
										>
											{parseInt(vehicles[vehicle]?.fuel)}{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="15"
												height="15"
												viewBox="0 0 48 48"
												className={
													"mt-[4px] " +
													getFuelLevelColorCSS(vehicles[vehicle]?.fuel)
												}
											>
												<path d="M0 0h48v48h-48z" fill="none" />
												<path d="M39.54 14.46l.03-.03-7.45-7.43-2.12 2.12 4.22 4.22c-1.88.72-3.22 2.53-3.22 4.66 0 2.76 2.24 5 5 5 .71 0 1.39-.15 2-.42v14.42c0 1.1-.9 2-2 2s-2-.9-2-2v-9c0-2.21-1.79-4-4-4h-2v-14c0-2.21-1.79-4-4-4h-12c-2.21 0-4 1.79-4 4v32h20v-15h3v10c0 2.76 2.24 5 5 5s5-2.24 5-5v-19c0-1.38-.56-2.63-1.46-3.54zm-15.54 5.54h-12v-10h12v10zm12 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
											</svg>
										</p>
									) : null}
									{extraData && vehicles[vehicle]?.temp ? (
										<p
											className={
												"flex text-xl font-semibold ml-0.5 " +
												getTempLevelColor(vehicles[vehicle]?.temp)
											}
										>
											{parseInt(vehicles[vehicle]?.temp)}°
										</p>
									) : null}
								</div>
								<p
									className={
										"text-2xl " +
										getVehicleAssignmentColor(journeysData, vehicles[vehicle])
									}
								>
									{getVehicleAssignment(journeysData, vehicles[vehicle])}
								</p>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	));
};
