import { useState } from "react";

import InputFile from "../components/inputFile";
import View from "../components/view";
import InputDate from "../components/inputDate";
import { createWBFromRows, createXLSXFile } from "../helper/xlsx";
import { zeroed } from "../helper/date";
import { parseJSON } from "../helper/storage";

type StoppointsType = {
	[key: string]: string;
};

const LINES: any = {
	"640": {
		division: "E38",
	},
	646: {
		division: "E38",
	},
	"648": {
		division: "E38",
	},
	"653": {
		division: "E38",
	},
	662: {
		division: "E35",
	},
	663: {
		division: "E35",
	},
	667: {
		division: "E35",
	},
	"669": {
		division: "E35",
	},
};

export const getQueryBookings = async (date: Date) => {
	try {
		await navigator.clipboard.writeText(`
			SELECT [BookingData]
			FROM [I4M_EXTDB].[dbo].[BookingsLog]
			WHERE DATEPART(YEAR, OperatingCalendarDay) = ${date.getFullYear()} AND DATEPART(MONTH, OperatingCalendarDay) = ${
			date.getMonth() + 1
		}`);
	} catch (err) {
		console.error("Error when trying to use navigator.clipboard.writeText()", err);
	}
};

export const uniqueBookingArray = (array: any[]) => {
	const unqiue: any = {};

	for (let i = 0; i < array.length; i++) {
		const key = `${array[i].CalendarDay}-${getLine(
			array[i].Properties.line_external_id.slice(0, 3)
		)}-${array[i].Properties.journey_external_id}`;

		const new_booking = {
			...array[i],
			Properties: {
				...array[i].Properties,
				line_external_id: getLine(array[i].Properties.line_external_id),
			},
		};

		if (unqiue[key] === undefined) {
			unqiue[key] = {
				...new_booking,
				duration:
					(new Date(array[i].Points[1].PointTimestamp).getTime() -
						new Date(array[i].Points[0].PointTimestamp).getTime()) /
					60000,
			};
		} else {
			const newDuration =
				(new Date(array[i].Points[1].PointTimestamp).getTime() -
					new Date(array[i].Points[0].PointTimestamp).getTime()) /
				60000;

			unqiue[key] = {
				...new_booking,
				duration: unqiue[key].duration > newDuration ? unqiue[key].duration : newDuration,
			};
		}
	}

	return Object.values(unqiue);
};

const FAKE_LINES: any = {
	"669": "669Z",
	"640": "640Z",
	"648": "648Z",
	"653": "653V",
};

export const getLine = (line: string) => {
	if (FAKE_LINES[line]) {
		return FAKE_LINES[line];
	}

	return line;
};

const calculateAllBookings = (bookings: any) => {
	const uniqueBookings: any = {};

	console.log(bookings);

	for (let i = 0; i < bookings.length; i++) {
		const booking = bookings[i];
		const day = booking.CalendarDay.split("T")[0];
		const line = getLine(booking.Properties.line_external_id);
		const trip = booking.Properties.journey_external_id;
		const bookingCode = booking.BookingCode;
		const tripKey = `${day}-${line}-${trip}-${bookingCode}`;

		if (uniqueBookings[tripKey] === undefined) {
			uniqueBookings[tripKey] = [];
		}

		const new_booking = {
			...booking,
			Properties: {
				...booking.Properties,
				line_external_id: line,
			},
		};

		uniqueBookings[tripKey].push(new_booking);
	}

	return Object.values(uniqueBookings).map((booking: any) => booking[booking.length - 1]);
};

export default function BookingHelper() {
	const [view, setView] = useState<"start" | "loading" | "success" | "error">("start");

	const [date, setDate] = useState(new Date().toString());

	const [allBookings, setAllBookings] = useState<any>([]);

	const [bookingDataE35, setBookingdataE35] = useState<any>([]);
	const [bookingDataE38, setBookingdataE38] = useState<any>([]);

	const handleAPCData = (file: any) => {
		if (!file) {
			setView("error");
			return;
		}

		try {
			const reader = new FileReader();
			reader.onload = (event: any) => {
				// Parse data
				const bstr = event.target.result;

				setView("loading");

				const data = JSON.parse(bstr).map((row: any) => {
					const { BookingData } = row;

					return parseJSON(BookingData);
				});

				console.log(data);

				setAllBookings(calculateAllBookings(data));

				// Filter out unique bookings based on Id.
				const uniqueBookings: any = uniqueBookingArray(data).sort(
					(a: any, b: any) =>
						new Date(a.CalendarDay).getTime() - new Date(b.CalendarDay).getTime()
				);

				let copyableTableE38 = [];
				let copyableTableE35 = [];

				const linesTotalDuration: any = {};
				const linesTotalDistance: any = {};
				const linesAmount: any = {};

				for (let i = 0; i < uniqueBookings.length; i++) {
					const booking = uniqueBookings[i];
					const line = booking.Properties.line_external_id;
					const duration =
						booking.duration !== 0
							? booking.duration
							: Math.ceil(Math.random() * 7) + 10;
					const distance =
						Math.round(Math.floor(Math.random() * 10) + 65 * (duration / 60) * 100) /
						100;

					if (distance < 8 || parseFloat(duration) < 8.0) {
						continue;
					}

					if (linesTotalDuration[line]) {
						linesTotalDuration[line] += duration;
						linesTotalDistance[line] += distance;

						linesAmount[line] += 1;
					} else {
						linesTotalDuration[line] = duration;
						linesTotalDistance[line] = distance;
						linesAmount[line] = 1;
					}
				}

				for (let i = 0; i < uniqueBookings.length; i++) {
					const booking = uniqueBookings[i];
					const datum = booking.CalendarDay.split("T")[0];
					const line = booking.Properties.line_external_id;
					const division = LINES[line.slice(0, 3)].division;
					const trip = booking.Properties.journey_external_id;
					const duration =
						parseFloat(booking.duration) < 8.0
							? (linesTotalDuration[line] / linesAmount[line]).toFixed(2)
							: booking.duration;
					const rawDistance =
						Math.round(Math.floor(Math.random() * 10) + 65 * (duration / 60) * 100) /
						100;
					const distance =
						rawDistance < 8.0
							? (linesTotalDistance[line] / linesAmount[line]).toFixed(2)
							: rawDistance;

					if (division === "E35") {
						copyableTableE35.push([
							datum,
							line,
							trip,
							duration.toString().replace(".", ","),
							distance.toString().replace(".", ","),
						]);
					} else {
						copyableTableE38.push([
							datum,
							line,
							trip,
							duration.toString().replace(".", ","),
							distance.toString().replace(".", ","),
						]);
					}
				}

				setBookingdataE35(copyableTableE35);
				setBookingdataE38(copyableTableE38);
				setView("success");
			};

			reader.readAsBinaryString(file);
		} catch (error) {
			setView("error");
		}
	};

	const reset = () => {
		setView("start");
	};

	const writeToClipboard = async (division: string) => {
		try {
			const wb = createWBFromRows(division === "E35" ? bookingDataE35 : bookingDataE38, [
				"Datum",
				"Linje",
				"Tur",
				"Tid",
				"Distans",
			]);

			createXLSXFile(
				wb,
				`anrop-${division}-${zeroed(new Date(date).getMonth() + 1)}-${new Date(
					date
				).getFullYear()}.xlsx`
			);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	const getPointName = (stoppoints: any, point: any) => {
		if (point.AddressName) {
			return point.AddressName;
		} else if (point.StopPointId) {
			return stoppoints[point.StopPointId];
		} else {
			return point.Text ?? "Okänd";
		}
	};

	const createBookAll = async () => {
		try {
			const booking_array: any = [];

			const stoppoints = await fetch(
				"https://storage.vassdupp.com/clean_stoppoints_mapped.json",
				{
					method: "GET",
				}
			)
				.then((res) => res.json())
				.then((data) => {
					return data;
				});

			for (let i = 0; i < allBookings.length; i++) {
				const row = allBookings[i];

				if (i < 5) {
					console.log(row);
				}

				booking_array.push([
					row.CalendarDay.split("T")[0],
					row.Properties.line_external_id,
					row.Properties.journey_external_id,
					row.Points[0].PointTimestamp.split("T")[1].slice(0, 5),
					row.Points[row.Points.length - 1].PointTimestamp.split("T")[1].slice(0, 5),
					row.BookingCode,
					(new Date(row.Points[row.Points.length - 1].PointTimestamp).getTime() -
						new Date(row.Points[0].PointTimestamp).getTime()) /
						60000,
					getPointName(stoppoints, row.Points[0]),
					getPointName(stoppoints, row.Points[row.Points.length - 1]),
					row.Properties.passenger_count,
				]);
			}

			const wb = createWBFromRows(booking_array, [
				"Datum",
				"Linje",
				"Tur",
				"Starttid",
				"Ankomsttid",
				"Bokningskod",
				"Tid",
				"Från",
				"Till",
				"Resenärer",
			]);

			createXLSXFile(wb, `alla-bokningar.xlsx`);
		} catch (err) {
			console.error("Error when trying to use navigator.clipboard.writeText()", err);
		}
	};

	console.log(allBookings);

	return (
		<>
			<h1 className="font-bold text-2xl mt-12">Anropstrafikrapport</h1>
			<p className="mb-10 text-sm">Skapa rapport om anropstrafik</p>
			<View show={view === "start"}>
				<InputDate placeholder="Välj månad" value={date.toString()} onChange={setDate} />

				<button
					onClick={() => getQueryBookings(new Date(date))}
					className="mt-5 text-xs font-semibold self-start mb-5 py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
				>
					Kopiera query till ExtDB
				</button>

				<InputFile name="apcdata" accept=".json" onHandleFile={handleAPCData} />
			</View>
			<View show={view === "success"}>
				<p className="mt-6 font-semibold">Hantering av filer lyckades!</p>

				<div className="flex flex-col self-start">
					<button
						onClick={() => writeToClipboard("E35")}
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Ladda ner E35
					</button>

					<button
						onClick={() => writeToClipboard("E38")}
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Ladda ner E38
					</button>

					<button
						onClick={() => createBookAll()}
						className="mt-10 text-xs font-semibold py-3 px-12 bg-slate-200 rounded disabled:bg-gray-100 disabled:text-gray-300 dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					>
						Ladda ner alla bokningar
					</button>

					<button
						className="mt-4 text-xs underline font-semibold self-start rounded"
						onClick={() => reset()}
					>
						Starta om
					</button>
				</div>
			</View>
			<View show={view === "error"}>
				<p className="mt-6 font-semibold text-red-400">Något gick fel!</p>
				<p className="mt-2 text-xs">Ett okänt fel inträffade...</p>
				<button
					className="mt-10 text-xs font-semibold py-3 px-6 bg-slate-200 self-start rounded dark:bg-stone-900 dark:text-stone-100 dark:disabled:bg-stone-900 dark:disabled:text-stone-700"
					onClick={() => reset()}
				>
					Försok igen
				</button>
			</View>
		</>
	);
}
