import { useEffect, useState } from "react";
import { Circle, useMap } from "react-leaflet";

const DynamicCircle = ({
	center,
	radius = 50,
	opacity,
	fillOpacity,
	fillColor,
	color,
	children,
}: {
	center: any;
	radius: number;
	opacity?: number;
	fillOpacity?: number;
	fillColor?: string;
	color?: string;
	children?: any;
}) => {
	const map = useMap();
	const [dynamicRadius, setDynamicRadius] = useState(radius); // Default radius

	useEffect(() => {
		const updateRadius = () => {
			const zoom = map.getZoom();
			setDynamicRadius(
				Math.min(Math.max(radius * Math.exp((10 - zoom) * 0.1), radius), 5000)
			); // Scale radius dynamically
		};

		map.on("zoom", updateRadius);
		updateRadius(); // Set initial radius

		return () => {
			map.off("zoom", updateRadius);
		};
	}, [map]);

	return (
		<Circle
			center={center}
			radius={dynamicRadius}
			opacity={opacity}
			fillOpacity={fillOpacity}
			color={color}
			fillColor={fillColor}
		>
			{children}
		</Circle>
	);
};

export default DynamicCircle;
