import { Link } from "wouter";
import ContractSelector from "../../components/contractSelector";
import MonthSelector from "../../components/monthSelector";
import TableHeader from "../../components/tableHeader";
import { STATUS_COLOR_MAP, STATUS_TITLE_MAP } from "../../config";
import { getYearMonthFormat } from "../../helper/date";

import useFetch from "../../helper/swr";
import { TripStatus } from "../../types";
import { useState } from "react";
import { StatBox } from "../../components/statbox";
import usePersistentState from "../../helper/usePersistentState";
import { sortRows } from "../../helper/sorter";
import InputSelect from "../../components/inputSelect";

const HEADER_NAMES = [
	{ key: "operatingDay", name: "Datum", width: "w-36" },
	{ key: "line", name: "Linje", width: "w-28" },
	{ key: "trip", name: "Tur", width: "w-28" },
	{ key: "completeness", name: "Utförd (%)", width: "w-36" },
	{ key: "vehicle", name: "Fordon", width: "w-36" },
	{ key: "comment", name: "Kommentar", width: "flex-1" },
	{ key: "status", name: "Status", width: "w-36" },
];

const FILTERS: any = [
	["A", "Allt"],
	["F", "Ej slutförda"],
	["S", "Slutförda"],
	["K", "Konstiga turer"],
	["E", "Undantag"],
	["NE", "Inga undantag"],
	["VD", "Fordon saknar data"],
	["C", "Inställda"],
	["LC", "Inställda i efterhand"],
	["R", "Omlagd körväg"],
];

const TripRowStatus = ({ status }: { status: TripStatus }) => {
	return (
		<div className="flex flex-row">
			<p
				className={
					"font-medium whitespace-nowrap px-1.5 py-0.5 text-xs rounded " +
					STATUS_COLOR_MAP[status]
				}
			>
				{STATUS_TITLE_MAP[status]}
			</p>
		</div>
	);
};

export function TripRow({
	trip,
}: {
	trip: {
		operatingDay: string;
		line: string;
		trip: string;
		completeness: number;
		status: TripStatus;
		vehicle: string;
		comment: string;
	};
}) {
	return (
		<Link
			to={`/resend/trips/${trip.operatingDay}/${trip.line}/${trip.trip}/nc`}
			className="flex flex-row w-full items-center px-4 py-2 border-b border-slate-200 dark:border-stone-900 hover:bg-stone-50 dark:hover:bg-stone-950 ease-out transition-all"
		>
			<p className="font-semibold text-sm w-36">
				{trip.operatingDay.substring(0, 4)}-{trip.operatingDay.substring(4, 6)}-
				{trip.operatingDay.substring(6, 8)}
			</p>
			<div className="flex w-28">
				<p
					className={
						"font-semibold text-sm px-1.5 rounded bg-slate-200 dark:bg-blue-950 dark:text-blue-400"
					}
				>
					{trip.line}
				</p>
			</div>
			<p className="font-semibold text-sm w-28">{trip.trip}</p>
			<div className="flex w-36">
				<p
					className={
						"font-semibold text-xs px-2 py-0.5 rounded " +
						(trip.completeness === 100
							? "bg-green-200 dark:bg-green-500 dark:text-black"
							: "bg-red-200 dark:bg-red-500 dark:text-black")
					}
				>
					{trip.completeness}%
				</p>
			</div>
			<p className="font-semibold text-sm w-36">{trip.vehicle}</p>
			<p className="font-semibold text-sm  flex-1">{trip.comment}</p>
			{/* <TripRowReasons reasons={[]} /> */}
			{/* <TripRowExceptions exceptions={checkIfExceptions(trip)} /> */}

			<TripRowStatus status={trip.status} />
		</Link>
	);
}

const TripStats = ({
	stats,
	isLoading,
	isError,
}: {
	stats: any;
	isLoading: boolean;
	isError: boolean;
}) => {
	if (isLoading) {
		return null;
	}

	if (isError) {
		return null;
	}

	if (stats === undefined) {
		return null;
	}

	console.log(stats);

	return (
		<div className="flex justify-between mb-5 flex-wrap flex-col">
			<div className="flex flex-wrap">
				<StatBox
					title="Efter eftersändning"
					procentage={Number(
						100 *
							(1 -
								(stats["resend-failed"] +
									stats["looks-wierd"] +
									stats["no-vehicle-data"] +
									stats["waiting-data"]) /
									stats.trips)
					).toFixed(2)}
					value={
						stats["resend-failed"] +
						stats["looks-wierd"] +
						stats["no-vehicle-data"] +
						stats["waiting-data"]
					}
				/>

				<StatBox
					title="Enligt Trafikförvaltningen"
					procentage={Number(
						100 *
							(1 -
								(stats["resend-failed"] +
									stats["resend-cts"] +
									stats["resend-created"] +
									stats["waiting-data"] +
									stats.cancelled +
									stats["rerouted"] +
									stats["exception"] +
									stats["looks-wierd"] +
									stats["no-vehicle-data"]) /
									stats.trips)
					).toFixed(2)}
					value={
						stats["resend-failed"] +
						stats["resend-cts"] +
						stats["resend-created"] +
						stats["waiting-data"] +
						stats.cancelled +
						stats["rerouted"] +
						stats["exception"] +
						stats["looks-wierd"] +
						stats["no-vehicle-data"]
					}
				/>

				<StatBox
					title="Konstig tur"
					procentage={Number(100 * (stats["looks-wierd"] / stats.trips)).toFixed(2)}
					value={stats["looks-wierd"]}
				/>

				<StatBox
					title="Saknar GPS data"
					procentage={Number(100 * (stats["no-vehicle-data"] / stats.trips)).toFixed(2)}
					value={stats["no-vehicle-data"]}
				/>

				{/* 			<StatBox
				title="Väntar på data"
				procentage={Number(100 * (stats["waiting-data"] / stats.trips)).toFixed(2)}
				value={stats["waiting-data"]}
			/> */}

				<StatBox
					title="Undantag"
					procentage={Number(100 * (stats["exception"] / stats.trips)).toFixed(2)}
					value={stats["exception"]}
				/>

				<StatBox
					title="Omlagd körväg"
					procentage={Number(100 * (stats["rerouted"] / stats.trips)).toFixed(2)}
					value={stats["rerouted"]}
				/>

				<StatBox
					title="Inställda"
					procentage={Number(100 * (stats.cancelled / stats.trips)).toFixed(2)}
					value={stats.cancelled}
				/>

				<StatBox
					title="Inställd i efterhand"
					procentage={Number(100 * (stats["late-cancelled"] / stats.trips)).toFixed(2)}
					value={stats["rerouted"]}
				/>

				<StatBox
					title="Eftersända"
					procentage={Number(
						100 * ((stats["resend-cts"] + stats["resend-created"]) / stats.trips)
					).toFixed(2)}
					value={stats["resend-cts"] + stats["resend-created"]}
				/>

				{/* <StatBox
				title="Eftersända från CTS"
				procentage={Number(100 * (stats["resend-cts"] / stats.trips)).toFixed(2)}
				value={stats["resend-cts"]}
			/>

			<StatBox
				title="Eftersända skapade"
				procentage={Number(100 * (stats["resend-created"] / stats.trips)).toFixed(2)}
				value={stats["resend-created"]}
			/> */}

				{/* <StatBox
				title="Eftersända misslyckade"
				procentage={Number(100 * (stats["resend-failed"] / stats.trips)).toFixed(2)}
				value={stats["resend-failed"]}
			/> */}
			</div>
			<div className="px-2 py-2 bg-blue-50 flex self-start space-x-2">
				<div>
					<p>
						{new Intl.NumberFormat("sv-SE", { useGrouping: true }).format(
							(stats["resend-cts"] + stats["resend-created"]) * 1700
						)}
						kr
					</p>
				</div>

				<p>
					Total:{" "}
					{stats["resend-failed"] +
						stats["resend-created"] +
						stats["resend-cts"] +
						stats.cancelled +
						stats["rerouted"] +
						stats["exception"]}
				</p>
			</div>
		</div>
	);
};

const FILTER_MAP = {
	/* 	"cancelled": true, */
	/* 	"real-h19": true, */
	/* 	"false-h19": true, */
	/* 	"exception": true, */
	/* 	"close-h19": true, */
	/* 	"bad-resend": true, */
	/* "resend-failed": true, */
};

const Trips = ({
	trips,
	isLoading,
	isError,
	sortBy,
}: {
	trips: any;
	isLoading: boolean;
	isError: boolean;
	sortBy: string;
}) => {
	if (isLoading) {
		return <p className="m-auto text-blue-500">Laddar...</p>;
	}

	if (isError) {
		return <p className="m-auto text-red-500">Ett fel uppstod</p>;
	}

	if (trips === undefined || trips?.length === 0) {
		return <p className="m-auto text-stone-500">Inga turer hittades</p>;
	}

	const filteredTrips = sortRows(
		trips.filter((trip: any) => FILTER_MAP[trip.status] === undefined),
		sortBy
	);

	return (
		<div className="flex flex-1 w-full flex-col">
			{filteredTrips.map((trip: any, i: any) => (
				<TripRow key={"dr-" + i} trip={trip} />
			))}
		</div>
	);
};

export default function NotFinished() {
	const [month, setMonth] = usePersistentState("vassdupp-month", getYearMonthFormat());
	const [contract, setContract] = useState("ALL");
	const [page] = useState(1);
	const [sortBy, setSortBy] = usePersistentState("vassdupp-nc-filter", "status-asc");
	const [filter, setFilter] = usePersistentState("vassdupp-nc-filter", "A");

	const {
		data: trips,
		isLoading: isLoadingTrips,
		isError: isTripError,
	} = useFetch(`notcomplete?contract=${contract}&month=${month}&page=${page}&filter=${filter}`);
	const {
		data: stats,
		isLoading: isLoadingStats,
		isError: isStatsError,
	} = useFetch(`notcompleteoverview?contract=${contract}&month=${month}`);

	return (
		<div className="flex flex-1 px-6 flex-col">
			<div className="flex w-full justify-between items-center mb-6">
				<div>
					<h1 className="mt-12 font-semibold text-2xl">Ej slutförda turer</h1>
					{trips ? (
						<h1 className="font-semibold text-sm text-gray-700">
							{stats?.trips}st turer under period
						</h1>
					) : null}
					<p className="font-medium text-stone text-sm w-40 text-stone-500 mb-8 whitespace-nowrap">
						Alla turer som ej är slutförda i trafiken för en specifierad period
					</p>
				</div>
				<div className="flex">
					<InputSelect
						placeholder="Filtera"
						items={FILTERS}
						value={filter}
						onChange={setFilter}
					/>
					<MonthSelector value={month} onChange={setMonth} />
					<ContractSelector value={contract} onChange={setContract} />
				</div>
			</div>

			<TripStats stats={stats} isLoading={isLoadingStats} isError={isTripError} />

			<div className="flex flex-1 flex-col w-full">
				<TableHeader items={HEADER_NAMES} setSortBy={setSortBy} sortBy={sortBy} />
				<Trips
					trips={trips}
					isLoading={isLoadingTrips}
					isError={isStatsError}
					sortBy={sortBy}
				/>
			</div>
		</div>
	);
}
